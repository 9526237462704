<template>
  <div v-if="query != null">
    <h2 class="uk-text-center">Тестирование запроса</h2>
    <div class="uk-card uk-card-default uk-card-small uk-card-body" style="max-width:800px; margin: 0 auto">
      <form v-on:submit.prevent="submit" class="uk-form-horizontal">

          <div v-for="param in query.query_params" v-if='param.isInOrOut == "in"' class="uk-margin-small-bottom">
              <label class="uk-form-label" for="form-horizontal-text">{{param.name}}</label>
              <div class="uk-form-controls">
                  <input v-model="param.value" class="uk-input" type="text" :placeholder="param.type">
              </div>
          </div>

          <div class="uk-text-center uk-margin-top">
            <button v-if='!authorized' type="submit" class="uk-button uk-button-large uk-button-primary">Авторизация</button>
            <template v-if='authorized'>
              <p>
                Авторизован как {{tokenInfo.login ? tokenInfo.login : 'гость'}} ({{tokenInfo.user_id}}),
                проект {{tokenInfo.project_name}} 
                <button class="uk-button uk-button-small uk-button-secondary" v-on:click.prvent="changeUser">Сменить</button>
              </p>

              <p v-if="!queryStarted">
                <button @click.prenent="makeDump" type="button" class="uk-button uk-button-secondary">Дамп в файл</button>
              </p>
              <p>
                <button v-if="!queryStarted" type="submit" class="uk-button uk-button-primary">Показать</button>
                <select v-if="!queryStarted" v-model="rowCount" class="uk-select " style="width: 80px">
                  <option>10</option><option>50</option><option>100</option><option>1000</option><option>10000</option>
                </select>
                записей
              </p>
              <button v-if="queryStarted" disabled class="uk-button uk-button-primary">Выполняется</button>

            </template>
          </div>
          
          <div v-if="queryError !== null" class="uk-alert-danger" uk-alert>
            <p v-html="queryErrorHtml"></p>
          </div>
            
      </form>  
    </div>

    <div v-if="queryResult !== null" class="uk-margin-small-top">
      <ul uk-tab uk-switcher>
          <li v-for="res in queryResult"><a href="#">{{res.paramName}}</a></li>
      </ul>
      <ul class="uk-switcher uk-margin">
          <li v-for="res in queryResult">
            <div v-if="res.type == 'CURSOR'"  style="max-height:70vh" class="uk-overflow-auto">
              <table class="uk-table uk-table-divider uk-table-striped uk-table-small">
                <tr>
                  <th v-for="col in res.value.columns">{{col[0]}}</th>
                </tr>
                <tr v-for="row in res.value.list">
                  <td v-for="cell in row">{{cell}}</td>
                </tr>
              </table>
            </div>
            <p v-if="res.type != 'CURSOR'">{{res.value}}</p>
          </li>
      </ul>          
    </div>

    <modal-auth ref="authModal" @auth="auth"></modal-auth>    
  </div>
</template> 
<script>
import carabi from "../carabi.js";

function nl2br (str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export default {
  name: 'queriesEditorTest',
  data () { return {
    "queryResult" : null,
    "queryError" : null,
    "queryStarted" : false,
    "rowCount" : 10,
  }; },
  props: {
    "query" : null
  },
  computed: {
    curModId() {
      return this.$store.state.curModId;
    },
    queryErrorHtml() {
      return nl2br(this.queryError.Message);
    },
    tokenInfo() {
      return this.$store.state.userTokenInfo;
    },
    authorized() {
      return this.tokenInfo !== null;
    },
    queryParams() {
      let result = {};
      _.each(this.query.query_params,(param)=>{
        if (param.isInOrOut == "in") {
          result[param.name] = param.value;
        }
      });
      return result;
    }
  },
  methods : {
    async submit() {
      if (this.authorized) {
        console.log("submit",this.query.query_name,this.queryParams);
        this.queryError = null;
        this.queryResult = null;
        this.queryStarted = true;
        try {
          let result = await this.carabi.queryRaw(this.query.query_name,this.queryParams,this.rowCount,0,this.curModId)
          console.log("query result",result);
          this.queryResult = result;
        } catch (error) {
          console.log("query error",error);
          this.queryError = error;
        }
        this.queryStarted = false;
      } else {
        this.$refs.authModal.show();
      }
    },
    
    async makeDump() {
      this.queryError = null;
      this.queryStarted = true;
      try {
        let result = await this.carabi.query(this.query.query_name,this.queryParams,100000,0,this.curModId)
        download(this.query.query_name+'.json',JSON.stringify(result))
      } catch (error) {
        console.log("query error",error);
        this.queryError = error;
      }
      this.queryStarted = false;    
    },
    
    changeUser() {
      if (this.carabi !== null) {
        this.carabi.unregister();
      }
      this.carabi = null;
      this.$store.commit('setUserTokenInfo',null);
      this.$refs.authModal.show();
    },
    async auth(authParams) {
      console.log("auth",authParams);
      if (this.carabi !== null) {
        this.carabi.unregister();
      }
      try {
        this.carabi = new carabi(this.$store.state.curServer.address,authParams.project);
        if (authParams.login != '') {
          await this.carabi.authorize(authParams.login,authParams.password);
        }
        this.$refs.authModal.complete("");
        this.$refs.authModal.hide();
        let info = await this.carabi.tokenInfo();
        info.token = this.carabi.token
        this.$store.commit('setUserTokenInfo',info);
      } catch(error) {
        console.error('auth error',error);
        this.$refs.authModal.complete(error.Message);
      }
    }
  },
  mounted () {
    this.carabi = null;
    if (this.tokenInfo !== null) {
      console.log("tokeninfo!!!",this.tokenInfo)
      this.carabi = new carabi(this.$store.state.curServer.address,this.tokenInfo.project_name);
      this.carabi.token = this.tokenInfo.token
    }    
    /*
    if ((carabi.token != null) && (this.tokenInfo === null)) {
      carabi.tokenInfo().then((info)=>{
        this.$store.commit('setUserTokenInfo',info);
      }).catch((error)=>{
        //alert('setUserTokenInfo error');
        this.$store.commit('setUserTokenInfo',null);
      });
    }
    */
  }
}
</script>
 
<style>
</style>
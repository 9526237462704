<template>
  <div class="uk-margin-small-top">
    <div class="uk-text-large uk-margin-small-bottom">
      Рабочая версия {{curCommitView.ModID}}
    </div>
    <div v-if="fullpage">
      <button v-on:click.prevent="goBack()" class="uk-button uk-button-default uk-button-small"><span uk-icon="icon: arrow-left"></span> Назад</button>
      <button v-on:click.stop="selectCommit()" class="uk-button uk-button-small" :class="{ 'uk-button-primary': curCommitView.ModID === curModId, 'uk-button-secondary': curCommitView.ModID !== curModId }"> Запросы </button>
      <button v-on:click.stop="setDefault()" class="uk-button uk-button-small uk-button-secondary"> Установить По Умолчанию </button>
      <!--div class="uk-inline">
          <button class="uk-button uk-button-small uk-button-secondary" type="button">Назначить проекту</button>
          <div uk-dropdown>
              <ul class="uk-nav uk-dropdown-nav">
                class="uk-active"<li v-for="prj in projects"><a @click.prevent="setProject(prj.ProjectID)" href="#">
                  <span v-if="prj.ModID == curCommitView.ModID" uk-icon="check"></span>
                  {{prj.Name}}
                </a></li>
              </ul>
          </div>
      </div-->
    </div>
    <div class="uk-margin-small-top">
      <textarea v-model="commitMessage" :disabled="commiting" class="uk-textarea uk-width-1-1" rows="3" placeholder="Описание изменений..."></textarea>
      <button v-if="!commiting" v-on:click="commit()" class="uk-button uk-button-primary uk-margin-small-top"> Зафиксировать </button>
      <button v-else disabled class="uk-button uk-button-primary uk-margin-small-top"> Обрабатывается... </button>
    </div>
	</div>
</template>
<script>
import UIkit from "uikit";
import carabiAdmin from "../carabiAdmin.js";
import { mapState } from 'vuex';

export default {
  name: 'branchesCommit',
  props: [
    'fullpage',
    'curCommitView'
  ],
  data () { return {
    commitMessage: "",
    commiting: false
  }; },
  computed: mapState({
    curBranchID: state => state.curBranch.BranchID,
    curModId: 'curModId',
    projects: 'projects'
  }),
  methods: {
    goBack() {
      this.$store.commit('selectCommit',null);
    },
    selectCommit() {
      this.$store.commit('selectModId',this.curCommitView.ModID);
      this.$router.push('/queries');
    },
    async commit() {
      try {
        this.commiting = true
        this.$store.commit('setBranches',null);
        await carabiAdmin.applyCommit(
          this.curCommitView.ModID,
          this.curCommitView.BranchID,
          this.commitMessage
        );
        await this.$store.dispatch('loadBranches');
      } catch (e) {
        console.error('Commit Error',e)
        alert('Commit Error')
      }
      this.commiting = false
    },
    setProject(projectId) {
      let newProjects = _.cloneDeep(this.projects);
      _.each(newProjects,(val)=>{
        if (val.ProjectID == projectId) {
          val.ModID = this.curCommitView.ModID
        }
      });
      this.$store.commit('setProjects',newProjects);
    },
    async setDefault() {
      await carabiAdmin.setDefaultMod(this.curBranchID,this.curCommitView.ModID);
      UIkit.modal.alert('Модификация '+this.curCommitView.ModID+' задана всем клиентам!');
    }
  }
}
</script>

<style>
</style>

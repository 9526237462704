var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "uk-margin-small-top" }, [
    _c("h2", [_vm._v(_vm._s(_vm.title))]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "uk-form-horizontal uk-margin-large",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.projectSave()
          },
        },
      },
      [
        _c("div", { staticClass: "uk-margin-small-top" }, [
          _c(
            "label",
            {
              staticClass: "uk-form-label",
              attrs: { for: "form-horizontal-text" },
            },
            [_vm._v("Системное Имя*")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-form-controls" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.projectData.sysname,
                  expression: "projectData.sysname",
                },
              ],
              staticClass: "uk-input",
              attrs: { type: "text", placeholder: "myProject" },
              domProps: { value: _vm.projectData.sysname },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.projectData, "sysname", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-margin-small-top" }, [
          _c(
            "label",
            {
              staticClass: "uk-form-label",
              attrs: { for: "form-horizontal-text" },
            },
            [_vm._v("Описание")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-form-controls" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.projectData.description,
                  expression: "projectData.description",
                },
              ],
              staticClass: "uk-input",
              attrs: { type: "text", placeholder: "Новый проект" },
              domProps: { value: _vm.projectData.description },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.projectData, "description", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-margin-small-top" }, [
          _c(
            "label",
            {
              staticClass: "uk-form-label",
              attrs: { for: "form-horizontal-text" },
            },
            [_vm._v("DB Пользователь")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-form-controls" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.projectData.login,
                  expression: "projectData.login",
                },
              ],
              staticClass: "uk-input",
              attrs: { type: "text", placeholder: "" },
              domProps: { value: _vm.projectData.login },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.projectData, "login", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-margin-small-top" }, [
          _c(
            "label",
            {
              staticClass: "uk-form-label",
              attrs: { for: "form-horizontal-text" },
            },
            [_vm._v("DB Пароль")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-form-controls" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.projectData.password,
                  expression: "projectData.password",
                },
              ],
              staticClass: "uk-input",
              attrs: { type: "text", placeholder: "" },
              domProps: { value: _vm.projectData.password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.projectData, "password", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-margin-small-top" }, [
          _c(
            "label",
            {
              staticClass: "uk-form-label",
              attrs: { for: "form-horizontal-text" },
            },
            [_vm._v("Connection String")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "uk-form-controls" }, [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.projectData.connection_string,
                  expression: "projectData.connection_string",
                },
              ],
              staticClass: "uk-input",
              staticStyle: { height: "300px" },
              attrs: { type: "text", placeholder: "" },
              domProps: { value: _vm.projectData.connection_string },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.projectData,
                    "connection_string",
                    $event.target.value
                  )
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-margin-top uk-text-center" }, [
          _vm.projectData.ProjectID != 0
            ? _c(
                "button",
                {
                  staticClass: "uk-button uk-button-danger",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.projectDelete()
                    },
                  },
                },
                [_vm._v("Удалить")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "uk-button uk-button-primary",
              attrs: { type: "submit" },
            },
            [_vm._v("Сохранить")]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "uk-margin-small-top" },
    [
      _c("div", { staticClass: "uk-text-large uk-margin-small-bottom" }, [
        _vm._v(
          "\n    Версия " +
            _vm._s(_vm.curCommitView.ModID) +
            " от " +
            _vm._s(_vm.curCommitView.Created.format("L LT")) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "button",
          {
            staticClass: "uk-button uk-button-default uk-button-small",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.goBack()
              },
            },
          },
          [
            _c("span", { attrs: { "uk-icon": "icon: arrow-left" } }),
            _vm._v(" Назад"),
          ]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "uk-button uk-button-small",
            class: {
              "uk-button-primary": _vm.curCommitView.ModID === _vm.curModId,
              "uk-button-secondary": _vm.curCommitView.ModID !== _vm.curModId,
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.selectCommit()
              },
            },
          },
          [_vm._v(" Запросы ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "uk-button uk-button-small uk-button-secondary",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.setDefault()
              },
            },
          },
          [_vm._v(" Установить По Умолчанию ")]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "uk-button uk-button-small uk-button-secondary",
            on: {
              click: function ($event) {
                return _vm.newBranch()
              },
            },
          },
          [_vm._v(" Новая ветка ")]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-margin-small-top" }, [
        _vm._v("\n    " + _vm._s(_vm.curCommitView.Message) + "\n  "),
      ]),
      _vm._v(" "),
      _c("new-branch-modal", {
        ref: "branchModal",
        on: { complete: _vm.newBranchComplete },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "queries-content" },
    [
      _vm.loading ? _c("loading") : _vm._e(),
      _vm._v(" "),
      !_vm.loading && _vm.query === null
        ? _c(
            "div",
            {
              staticClass: "uk-alert-danger uk-margin-small-top",
              attrs: { "uk-alert": "" },
            },
            [_vm._m(0)]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading && _vm.query !== null
        ? _c(
            "div",
            [
              _vm.fullQuery.query_id != 0
                ? _c("h2", { staticClass: "uk-text-center" }, [
                    _vm._v("Редактирование запроса"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.fullQuery.query_id == 0
                ? _c("h2", { staticClass: "uk-text-center" }, [
                    _vm._v("Создание запроса"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "uk-breadcrumb" },
                [
                  _vm._l(_vm.breadcrumb, function (cat) {
                    return _c("li", [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.openCategory(cat)
                            },
                          },
                        },
                        [_vm._v(_vm._s(cat.Name))]
                      ),
                    ])
                  }),
                  _vm._v(" "),
                  _c("li", [
                    _c("span", [_vm._v(_vm._s(_vm.fullQuery.query_name))]),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "uk-grid-small uk-margin-small-top",
                  attrs: { "uk-grid": "" },
                },
                [
                  _c("div", { staticClass: "uk-width-1-2" }, [
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.fullQuery.query_name,
                            expression: "fullQuery.query_name",
                          },
                        ],
                        staticClass: "uk-input uk-width-1-1",
                        attrs: { type: "text", placeholder: "Имя запроса" },
                        domProps: { value: _vm.fullQuery.query_name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.fullQuery,
                              "query_name",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin-small-top" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.fullQuery.query_descr,
                            expression: "fullQuery.query_descr",
                          },
                        ],
                        staticClass: "uk-textarea uk-width-1-1",
                        staticStyle: { height: "100px" },
                        attrs: { placeholder: "Описание запроса" },
                        domProps: { value: _vm.fullQuery.query_descr },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.fullQuery,
                              "query_descr",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "uk-width-1-2" },
                    [
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass:
                              "uk-button uk-button-secondary uk-width-1-1",
                            on: { click: _vm.autoParams },
                          },
                          [_vm._v("Авто параметры")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "uk-margin-small-top" },
                        [
                          _c("queries-editor-params", {
                            attrs: { params: _vm.fullQuery.query_params },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-margin-small-top" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "uk-button uk-button-secondary uk-width-1-1",
                            on: {
                              click: function ($event) {
                                _vm.showSource = !_vm.showSource
                              },
                            },
                          },
                          [_vm._v("Исходный код")]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.showSource
                        ? _c("queries-editor-source", {
                            attrs: { query: _vm.fullQuery },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "uk-margin-small-top" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.fullQuery.query_text,
                      expression: "fullQuery.query_text",
                    },
                  ],
                  staticClass: "uk-textarea uk-width-1-1",
                  staticStyle: { height: "300px" },
                  attrs: { placeholder: "Содержимое запроса" },
                  domProps: { value: _vm.fullQuery.query_text },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.fullQuery, "query_text", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _vm.saveError != ""
                ? _c(
                    "div",
                    {
                      staticClass: "uk-alert-danger uk-margin-small-top",
                      attrs: { "uk-alert": "" },
                    },
                    [
                      _c("p", {
                        domProps: { innerHTML: _vm._s(_vm.saveError) },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "uk-text-center uk-margin-top" }, [
                !_vm.saving
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "uk-button uk-button-large uk-button-primary",
                        on: {
                          click: function ($event) {
                            return _vm.save()
                          },
                        },
                      },
                      [_vm._v("Сохранить")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.saving
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "uk-button uk-button-large uk-button-primary",
                        attrs: { disabled: "" },
                      },
                      [_vm._v("Сохранение")]
                    )
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _vm.fullQuery.query_id != 0
                ? _c("queries-editor-test", { attrs: { query: _vm.fullQuery } })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", { attrs: { "uk-icon": "icon: arrow-left" } }),
      _vm._v(" Искомый запрос не найден в дереве\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="branches">
    <div v-if="loading" class="branches-loader uk-text-center uk-padding">
      <div uk-spinner="ratio: 2"></div>
      <p>Загрузка...</p>
    </div>

    <div v-if="!loading" class="branches-panel">
      <ul class="uk-nav uk-nav-default uk-margin-small-top uk-margin-small-left uk-margin-small-right">
          <li v-for="br in branches" v-bind:class="{ 'uk-active': (br.BranchID == curBranchId) }">
            <a v-on:click.prevent="changeBranch(br)" href="#">
              <span class="uk-margin-small-right" uk-icon="icon: git-branch" />
              <strong>{{br.BranchName}}</strong><br/>
              {{br.BranchDescr}}
            </a>
          </li>
      </ul>
    </div>
    <div v-if="!loading"  class="branches-content">
      <div v-if="curBranchId === null" class="uk-alert-primary uk-margin-small-top" uk-alert>
        <p><span uk-icon="icon: arrow-left"></span> Выберите ветку разработки из списка</p>
      </div>
      <branches-list v-if="curCommitView === null && curBranchId !== null"></branches-list>
      <branches-commit :curCommitView="curCommitView" fullpage="true" v-if="curCommitView !== null && !curCommitView.IsApplied"></branches-commit>
      <branches-commit-applied v-if="curCommitView !== null && curCommitView.IsApplied"></branches-commit-applied>
    </div>

  </div>
</template>

<script>
export default {
  name: 'branches',
  data () { return {
    "loading" : true
  }; },
  computed: {
    branches() {
      return this.$store.state.branches;
    },
    curBranch() {
      return this.$store.state.curBranch;
    },
    curBranchId() {
      let curBranch = this.$store.state.curBranch;
      console.log("let curBranch",curBranch);
      if (curBranch !== null) {
        return curBranch.BranchID;
      } else {
        return null;
      }
    },
    curCommitView() {
      return this.$store.state.curCommitView;
    },
    curModId() {
      return this.$store.state.curModId;
    }
  },
  methods: {
    changeBranch(newBranch) {
      localStorage.setItem('curBranch',newBranch.BranchName);
      this.$store.commit('selectBranch',newBranch);
    }
  },
  created() {
    if (!this.$store.state.isDeveloper) {
      this.$router.push('/')
    }
  },
  beforeMount () {
    this.loading = false;
  }
}
</script>

<style>
.branches {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
}
.branches-loader {
    flex-grow: 1;
}
.branches-panel {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  overflow-y: scroll;
}
.branches-content {
    flex-grow: 1;
    overflow-y: scroll;
    padding: 0 10px;
}
</style>

<template>
  <div class="uk-modal-container uk-flex-top" uk-modal>
      <div class="uk-modal-dialog">
          <button class="uk-modal-close-default" type="button" uk-close></button>
          <div class="uk-modal-header">
              <h2 class="uk-modal-title">Импрот категории</h2>
          </div>
          <div class="uk-modal-body">
              <textarea v-model="content" :disabled="loading" class="uk-textarea" style="height: 60vh"></textarea>
          </div>
          <div class="uk-modal-footer uk-text-right">
              <div v-if="error" class="uk-margin-small-bottom uk-text-center uk-text-danger">
                {{error}}
              </div>
              <button class="uk-button uk-button-default uk-modal-close" type="button">Отмена</button>
              <button v-on:click="submit" v-if="!loading" class="uk-button uk-button-primary" type="button">Импорт</button>
              <button v-if="loading" class="uk-button uk-button-primary" type="button" disabled>Загрузка</button>
          </div>
      </div>
  </div>
</template>

<script>
import UIkit from "uikit";
import carabiAdmin from "../carabiAdmin.js";

export default {
  name: 'queriesCategoryImportModal',
  data() { return {
    loading: false,
    error: false,
    content : ""
  }},
  computed: {
    category() {
      return this.$store.state.curCategory;
    },
    curModId() {
      return this.$store.state.curModId;
    }
  },
  props: {
  },
  methods : {
    show() {
      UIkit.modal(this.$el).show();
    },
    hide() {
      UIkit.modal(this.$el).hide();
    },
    async submit() {
      try {
        this.loading = true
        let importObj = null
        try {
          importObj = JSON.parse(this.content)
        } catch(e) {
          throw {Message: "Введен нерорректный JSON", SymbCode: "INVALID_JSON"}
        }
        //console.log("importObj",importObj);
        await carabiAdmin.uploadObjects(this.curModId,this.category.ObjectID,[importObj])
        UIkit.modal(this.$el).hide()
        this.$emit("complete")
      } catch (e) {
        console.error('Import Error',e)
        let error = 'Ошибка импорта'
        if (e.Message) {
          error = e.Message
        }
        if (e.SymbCode) {
          error = e.SymbCode+': '+error
        }
        this.error = error
      }
      this.loading = false
    }
  },
  beforeMount () {
  }
}
</script>

<style>
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "branches" }, [
    _vm.projects !== null
      ? _c("div", { staticClass: "projects-panel" }, [
          _c(
            "ul",
            {
              staticClass:
                "uk-nav uk-nav-default uk-margin-small-top uk-margin-small-left uk-margin-small-right",
            },
            [
              _c("li", { class: { "uk-active": _vm.curProject === null } }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changeProject(null)
                      },
                    },
                  },
                  [
                    _c("span", {
                      staticClass: "uk-margin-small-right",
                      attrs: { "uk-icon": "icon: plus" },
                    }),
                    _vm._v(" "),
                    _c("strong", [_vm._v("Новый проект")]),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.projects, function (proj) {
                return _c(
                  "li",
                  {
                    key: proj.sysname,
                    class: { "uk-active": proj.sysname == _vm.curProjectId },
                  },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.changeProject(proj)
                          },
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "uk-margin-small-right",
                          attrs: { "uk-icon": "icon: database" },
                        }),
                        _vm._v(" "),
                        _c("strong", [_vm._v(_vm._s(proj.sysname))]),
                      ]
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.projects !== null
      ? _c(
          "div",
          { staticClass: "branches-content" },
          [_c("projects-editor")],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
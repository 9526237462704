<template>
  <div class="uk-margin-small-top">

    <h2>{{title}}</h2>

    <form v-on:submit.prevent="projectSave()" class="uk-form-horizontal uk-margin-large">

        <div class="uk-margin-small-top">
            <label class="uk-form-label" for="form-horizontal-text">Системное Имя*</label>
            <div class="uk-form-controls">
                <input v-model="projectData.sysname" class="uk-input" type="text" placeholder="myProject" />
            </div>
        </div>

        <div class="uk-margin-small-top">
            <label class="uk-form-label" for="form-horizontal-text">Описание</label>
            <div class="uk-form-controls">
                <input v-model="projectData.description" class="uk-input"  type="text" placeholder="Новый проект" />
            </div>
        </div>

        <div class="uk-margin-small-top">
            <label class="uk-form-label" for="form-horizontal-text">DB Пользователь</label>
            <div class="uk-form-controls">
                <input v-model="projectData.login" class="uk-input"  type="text" placeholder="" />
            </div>
        </div>

        <div class="uk-margin-small-top">
            <label class="uk-form-label" for="form-horizontal-text">DB Пароль</label>
            <div class="uk-form-controls">
                <input v-model="projectData.password" class="uk-input"  type="text" placeholder="" />
            </div>
        </div>

        <div class="uk-margin-small-top">
            <label class="uk-form-label" for="form-horizontal-text">Connection String</label>
            <div class="uk-form-controls">
                <textarea v-model="projectData.connection_string" style="height: 300px" class="uk-input" type="text" placeholder="" />
            </div>
        </div>

        <div class="uk-margin-top uk-text-center">
            <button @click="projectDelete()" type="button" v-if="projectData.ProjectID != 0" class="uk-button uk-button-danger">Удалить</button>
            <button type="submit" class="uk-button uk-button-primary">Сохранить</button>
        </div>

    </form>

  </div>
</template>

<script>
import UIkit from "uikit";
import { mapState } from 'vuex';
import carabiAdmin from "../carabiAdmin.js";

export default {
  name: 'projectsEditor',
  data () { return {
    projectData : {}
  }; },
  computed: mapState({
    title(state){
      return (state.curProject!==null)?(state.curProject.schema_id+'. '+state.curProject.sysname):'Новый проект'
    },
    curProject:'curProject'
  }),
  watch: {
    curProject: function () {
      this.projectLoad();
    }
  },
  mounted () {
    this.projectLoad();
  },
  methods: {
    projectLoad() {
      if (this.curProject === null) {
        this.projectData = {
          connection_string:"",
          description:"",
          login:"",
          password:"",
          schema_id:"-1",
          sysname:""
        };
      } else {
        this.projectData = _.cloneDeep(this.curProject);
      }
    },
    async projectSave() {
      if (!this.projectData.sysname) {
        alert('Введите Системное Имя!')
        return
      }
      await carabiAdmin.setProjectItem(this.projectData)
      await this.$store.dispatch('loadProjects')
      let newProj = this.$store.state.projects.find((prj)=>(prj.sysname === this.projectData.sysname))
      this.$store.commit('selectProject',newProj)
      alert('Сохранено')
    },
    async projectDelete() {
      if (!confirm("Удалить проект "+this.projectData.sysname+"?")) return;
      await carabiAdmin.deleteProjectItem(this.projectData.schema_id)
      this.$store.commit('selectProject',null)
      await this.$store.dispatch('loadProjects')
    }
  }
}
</script>

<style>
</style>

<template>
  <div id="modal-branch" uk-modal>
      <div class="uk-modal-dialog">
          <button class="uk-modal-close-default" type="button" uk-close></button>
          <div class="uk-modal-header">
              <h2 class="uk-modal-title">Новая ветка</h2>
          </div>
          <div class="uk-modal-body">
              <form v-on:submit.prevent="submit">
                <div>
                  <input v-model="branch_name" class="uk-input" type="text" placeholder="Название ветки">
                </div>
                <div class="uk-margin-small-top">
                  <input v-model="branch_descr" class="uk-input" type="text" placeholder="Описание ветки">
                </div>
                <div class="uk-margin-small-top uk-text-center">
                  <div v-if="error">{{error}}</div>
                </div>
              </form>
          </div>
          <div class="uk-modal-footer uk-text-right">
              <button class="uk-button uk-button-default uk-modal-close" type="button">Отмена</button>
              <button v-on:click="submit" v-if="!loading" class="uk-button uk-button-primary" type="button">Создать</button>
              <button v-if="loading" class="uk-button uk-button-primary" type="button" disabled>Загрузка</button>
          </div>
      </div>
  </div>
</template>
 
<script>
import UIkit from "uikit";
import carabiAdmin from "../carabiAdmin.js";

export default {
  name: 'newBranchModal',
  data() { return {
    loading: false,
    error: false,
    branch_name : "",
    branch_descr : ""
  }},
  computed: {
    curCommitView() {
      return this.$store.state.curCommitView;
    }  
  },
  props: {
  },
  methods : {
    show() {
      UIkit.modal($("#modal-branch")).show();
    },
    hide() {
      UIkit.modal($("#modal-branch")).hide();
    },
    submit() {
      this.loading = true;
      carabiAdmin.addBranch(
        this.curCommitView.ModID,
        this.branch_name,
        this.branch_descr
      ).then((result)=>{
        this.loading = false;
        this.hide();
        this.$emit('complete',result);
      });
      //this.error = "123";
    }
  },
  beforeMount () {
  }
}
</script>

<style>
</style>
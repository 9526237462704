var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.authorized
    ? _c("auth", { ref: "auth", on: { auth: _vm.auth } })
    : _c("layout", { on: { logout: _vm.logout } })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "branches" }, [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "branches-loader uk-text-center uk-padding" },
          [
            _c("div", { attrs: { "uk-spinner": "ratio: 2" } }),
            _vm._v(" "),
            _c("p", [_vm._v("Загрузка...")]),
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.loading
      ? _c("div", { staticClass: "branches-panel" }, [
          _c(
            "ul",
            {
              staticClass:
                "uk-nav uk-nav-default uk-margin-small-top uk-margin-small-left uk-margin-small-right",
            },
            _vm._l(_vm.branches, function (br) {
              return _c(
                "li",
                { class: { "uk-active": br.BranchID == _vm.curBranchId } },
                [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.changeBranch(br)
                        },
                      },
                    },
                    [
                      _c("span", {
                        staticClass: "uk-margin-small-right",
                        attrs: { "uk-icon": "icon: git-branch" },
                      }),
                      _vm._v(" "),
                      _c("strong", [_vm._v(_vm._s(br.BranchName))]),
                      _c("br"),
                      _vm._v(
                        "\n            " +
                          _vm._s(br.BranchDescr) +
                          "\n          "
                      ),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.loading
      ? _c(
          "div",
          { staticClass: "branches-content" },
          [
            _vm.curBranchId === null
              ? _c(
                  "div",
                  {
                    staticClass: "uk-alert-primary uk-margin-small-top",
                    attrs: { "uk-alert": "" },
                  },
                  [_vm._m(0)]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.curCommitView === null && _vm.curBranchId !== null
              ? _c("branches-list")
              : _vm._e(),
            _vm._v(" "),
            _vm.curCommitView !== null && !_vm.curCommitView.IsApplied
              ? _c("branches-commit", {
                  attrs: { curCommitView: _vm.curCommitView, fullpage: "true" },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.curCommitView !== null && _vm.curCommitView.IsApplied
              ? _c("branches-commit-applied")
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", { attrs: { "uk-icon": "icon: arrow-left" } }),
      _vm._v(" Выберите ветку разработки из списка"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
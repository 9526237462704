class sqlParser { 
  /**
	* Дополнение массива параметров baseParams элементами из newParams
	*/
  mergeParameters(baseParams,newParams) {
    for (let i in newParams) {
      let found = false;
      for (let j in baseParams) {
        if (newParams[i] == baseParams[j].name) {
          found = true;
          break;
        }
      }
      if (!found) {
        baseParams.push({
          "name" : newParams[i],
          "type" : "varchar",
          "isInOrOut" : "in"
        });
      }
    }
  }
  
  /**
	 * Построение таблицы параметров для запроса по его исходному коду
	 * @param sqlCode исходный код
	 */
	scanParametersList(sqlCode) {
		sqlCode = this.removeComments(sqlCode);
		let paramRegExp = /:[\w\$]+/g;
		
		let parametersFromCode = sqlCode.match(paramRegExp); //Параметры, найденные в коде регулярным выражением
		if (parametersFromCode === null) {
			parametersFromCode = [];
		}
		let dublicates = [];//поиск дублирующихся параметров
		let dublicatesWarned = [];
		let l = parametersFromCode.length;
		for (let i = 0; i < l; i++) {
			parametersFromCode[i] = parametersFromCode[i].substr(1);//отрезаем ":"
			if (dublicates.indexOf(parametersFromCode[i]) >= 0 && dublicatesWarned.indexOf(parametersFromCode[i]) < 0 ) {
				alert("Параметр " + parametersFromCode[i] + 
						" встречается в коде более одного раза,\nзапрос не будет работаь корректно."+
						"\nВероятно, следует использовать переменную.");
				dublicatesWarned[i] = parametersFromCode[i];
			}
			dublicates[i] = parametersFromCode[i];
		}
    return parametersFromCode;
	}

	/**
	 * Удаление комментариев из PL/SQL-кода
	 */
	removeComments(sqlScript) {
		let l = sqlScript.length;
		if (l <= 1) {
			return sqlScript;
		}
		let withoutComments = "";
		let lineOpened = false;
		let blockOpened = false;
		for (let i = 0; i < l-1; i++) {
			if (lineOpened) {
				if (sqlScript.charAt(i) == '\n') {
					lineOpened = false;
					withoutComments = withoutComments.concat('\n');
				}
				continue;
			}
			if (blockOpened) {
				if (sqlScript.charAt(i) == '*' && sqlScript.charAt(i+1) == '/') {
					i += 1;
					blockOpened = false;
				}
				continue;
			}
			if (sqlScript.charAt(i) == '-' && sqlScript.charAt(i + 1) == '-') {
				i += 1;
				lineOpened = true;
				continue;
			}
			if (sqlScript.charAt(i) == '/' && sqlScript.charAt(i + 1) == '*') {
				i += 1;
				blockOpened = true;
				continue;
			}
			if (! (lineOpened || blockOpened)) {
				withoutComments = withoutComments.concat(sqlScript.charAt(i));
			}
		}
		if (! (lineOpened || blockOpened)) {
			withoutComments = withoutComments.concat(sqlScript.charAt(l-1));
		}
		return withoutComments;
	}
}

export default new sqlParser();
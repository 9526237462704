<template>
  <div  v-if="(queryIds !== null) || (category !== null)" class="uk-alert-warning" uk-alert>
      <p v-if="queryIds !== null">Выбраны запросы ({{queryIds.length}} шт) для перемещения. Выберите новую категорию и нажмите "Подтвердить".</p>
      <p v-if="category !== null">Выбрана категория "{{category.Name}}" для перемещения. Выберите новую родительскую категорию и нажмите "Подтвердить".</p>
      <button v-on:click="confirm()" class="uk-button uk-button-small uk-button-primary uk-button-small uk-margin-small-right uk-margin-small-bottom">
        Подтвердить
      </button>    
      <button v-on:click="cancel()" class="uk-button uk-button-small uk-button-primary uk-button-small uk-margin-small-right uk-margin-small-bottom">
        Отмена
      </button>      
  </div>
</template> 
<script>
export default {
  name: 'queriesSelectBox',
  data () { return {  
  }; },
  computed: {
    queryIds() {
      return this.$store.state.selectedItems.queryIds;
    },
    category() {
      return this.$store.state.selectedItems.category;
    }
  },
  methods: {
    cancel() {
      this.$store.commit('selectedClear');
    },
    confirm() {
      this.$emit('confirm');
    }
  }
}
</script>
 
<style>
</style>
<template>
  <div class="uk-modal-container uk-flex-top" uk-modal>
      <div class="uk-modal-dialog">
          <button class="uk-modal-close-default" type="button" uk-close></button>
          <div class="uk-modal-header">
              <h2 class="uk-modal-title">Экспорт категории</h2>
          </div>
          <div class="uk-modal-body">
              <textarea v-model="content"  class="uk-textarea" style="height: 70vh"></textarea>
          </div>
      </div>
  </div>
</template>
 
<script>
import UIkit from "uikit";
import carabiAdmin from "../carabiAdmin.js";

export default {
  name: 'queriesCategoryExportModal',
  data() { return {
    content : ""
  }},
  computed: {
    category() {
      return this.$store.state.curCategory;
    },
    curModId() {
      return this.$store.state.curModId;
    }
  },
  props: {
  },
  methods : {
    async show() {
      this.content = "Загрузка...";
      UIkit.modal(this.$el).show();
      let exportObj = await carabiAdmin.getObjectStructure(this.curModId,this.category.ObjectID);
      this.content = JSON.stringify(exportObj, null, 2);      
    },
    hide() {
      UIkit.modal(this.$el).hide();
    }
  },
  beforeMount () {
  }
}
</script>

<style>
</style>
import Cookies from 'js-cookie';
import jsSHA from 'jssha';
import async from 'async';

class carabiAdmin {

  constructor() {
    console.log("carabiAdmin constructor");
    this.config = null;
    this.token = this.cookieOrNull('admin_token');
  }

  async auth(login,passw) {
    console.log("auth",login,passw);

    if (this.token == null) {
      let regResult = await this.serverHttp('admin/register',{});
      this.token = regResult.admin_token;
      console.log('adminToken',this.token);
    }

    let passwHash = this.sha256(passw);
    console.log('passwHash 1',passwHash);
    passwHash = this.sha256(login+passwHash);
    console.log('passwHash 2',passwHash);
    passwHash = this.sha256(passwHash);
    //passwHash = sha256(adminToken+passwHash);
    console.log('passwHash 3',passwHash);

    let authResult = await this.serverHttp('admin/auth',{
      "admin_token" : this.token,
      "login" : login,
      "passhash" : passwHash
    });
    Cookies.set('admin_token',this.token,{expires:7,path:''});
  }

  async getBranchModList() {
    return await this.serverHttp('admin/API/GetBranchModList',{
      "admin_token": this.token
    });
  }

  async getProjectsList() {
    let rawResult = await this.serverHttp('admin/getProjectsList',{
      "admin_token": this.token
    });
    let rawResult2 = this.convertOutput(rawResult.OutParams);
    return rawResult2.result;
  }

  async setProjectItem(item) {
    let params = {
      admin_token: this.token,
      id: parseInt(item.schema_id),
      login: item.login,
      sysname: item.sysname,
      password: item.password,
      description: item.description,
      connection_string: item.connection_string
    }
    return await this.serverHttp('admin/setProjectItem',params)
  }

  async deleteProjectItem(itemId) {
    return await this.serverHttp('admin/deleteProjectItem',{
      admin_token: this.token,
      id: itemId
    })
  }

  async applyCommit(modId,branchId,message) {
    return await this.serverHttp('admin/API/ApplyCommit',{
      "admin_token": this.token,
      "mod_id" : modId,
      "branch_id" : branchId,
      "message" : message
    });
  }

  async addBranch(modId,name,descr) {
    return await this.serverHttp('admin/API/AddBranch',{
      "admin_token": this.token,
      "name" : name,
      "descr" : descr,
      "mod_id" : modId
    });
  }

  async addService(modId,serviceName) {
    return await this.serverHttp('admin/API/addService',{
      "admin_token": this.token,
      "mod_id" : modId,
      "name" : serviceName,
      "descr" : ""
    });
  }

  async addCategory(modId,parentId,catName) {
    return await this.serverHttp('admin/API/addCategory',{
      "admin_token": this.token,
      "parent_id" : parentId,
      "mod_id" : modId,
      "name" : catName,
      "descr" : ""
    });
  }

  async setObjectProperty(modId,objectId,prop,value) {
    return await this.serverHttp('admin/API/SetObjectProperty',{
      "admin_token": this.token,
      "object_id" : objectId,
      "mod_id" : modId,
      "prop_name" : prop,
      "value" : value
    });
  }

  async setParent(modId,objectId,newParentId) {
    return await this.serverHttp('admin/API/SetParent',{
      "admin_token": this.token,
      "object_id" : objectId,
      "mod_id" : modId,
      "parent_id" : newParentId
    });
  }

  setParentIds(modId,objectIds,newParentId) {
    let promises = [];
    _.each(objectIds,(objectId) => {
      promises.push(this.setParent(modId,objectId,newParentId));
    })
    return Promise.all(promises);
  }

  async deleteObject(modId,objectId) {
    return await this.serverHttp('admin/API/DeleteObject',{
      "admin_token": this.token,
      "object_id" : objectId,
      "mod_id" : modId
    });
  }

  deleteObjectIds(modId,objectIds) {
    let promises = [];
    _.each(objectIds,(objectId) => {
      promises.push(this.deleteObject(modId,objectId));
    })
    return Promise.all(promises);
  }

  async getQuery(modId,queryName) {
    let result = await this.serverHttp('admin/API/getQuery',{
      "admin_token": this.token,
      "query_name" : queryName,
      "mod_id" : modId
    });
    return result.Query;
  }

  async setQuery(modId,query) {
    let result = await this.serverHttp('admin/API/setQuery',{
      "admin_token": this.token,
      "query" : query,
      "mod_id" : modId
    });
    return result.query_id;
  }

  async getObjectStructure(modId,objectId) {
    let result = await this.serverHttp('admin/API/GetObjectStructure',{
      "admin_token": this.token,
      "object_id" : objectId,
      "mod_id" : modId
    });
    return result.Items;
  }

  async uploadObjects(modId,parentId,objects) {
    await this.serverHttp('admin/API/UploadObjects',{
      "admin_token": this.token,
      "parent_id" : parentId,
      "mod_id" : modId,
      "objects" : objects
    });
  }

  async setDefaultMod(branchId,modId) {
    await this.serverHttp('admin/API/SetCurrentMod',{
      "admin_token": this.token,
      "mod_id": modId,
      "branch_id": branchId
    });
  }

  async getActiveUsers() {
    let rawResult = await this.serverHttp('admin/getActiveUsers',{
      "admin_token": this.token
    });
    let result = [];
    for (let prj in rawResult.projects) {
      let project = rawResult.projects[prj];
      if (Array.isArray(project)) {
        project.forEach((user)=>{
          user.project = prj;
          result.push(user);
        });
      }
    }
    console.log('getActiveUsers',rawResult,result);
    return result;
  }

  logout() {
    this.serverHttp('admin/unauth',{
      "admin_token" : this.token
    });
    this.token = null;
    Cookies.remove('admin_token',{path:''});
  }

  async serverHttp(url,params) {
    console.log('serverHttp',url,params);

    let response = await fetch(this.config.address+url,{
      method : "POST",
      body : JSON.stringify(params)
    });
    let result = await response.json();
    if (typeof result.SymbCode != "string") {
      result.SymbCode = "OK";
    }
    if (result.SymbCode == "OK") {
      console.log('serverHttpSuccess',result);
      return result;
    } else if (result.SymbCode == "INVALID_TOKEN") {
      logout();
      throw result;
    } else {
      throw result;
    }
  }

  convertOutput(output) {
    let newResult = {};
    for (let i in output) {
      if (output[i].type == 'CURSOR') {
        let cursor = output[i].value;
        let newCursor = [];
        for (let j in cursor.list) {
          let newRow = {};
          for (let k in cursor.list[j]) {
            newRow[cursor.columns[k][0]] = cursor.list[j][k];
          }
          newCursor.push(newRow);
        }
        output[i].value = newCursor;
      }
      newResult[output[i].paramName] = output[i].value;
    }
    return newResult;
  }

  cookieOrNull(name) {
    let result = Cookies.get(name);
    if (result === undefined) {
      result = null;
    }
    return result;
  }

  sha256(str) {
      let shaObj = new jsSHA("SHA-256", "BYTES");
      shaObj.update(str);
      return shaObj.getHash("HEX");
  }
}

export default new carabiAdmin();

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.curModId === null
    ? _c(
        "div",
        {
          staticClass: "uk-alert-primary uk-margin-small-top",
          attrs: { "uk-alert": "" },
        },
        [_vm._m(0)]
      )
    : !_vm.loaded
    ? _c("loading")
    : _c("div", { staticClass: "queries-cont" }, [
        _vm.curMod.IsApplied
          ? _c(
              "div",
              {
                staticClass: "uk-alert-warning uk-margin-remove",
                attrs: { "uk-alert": "" },
              },
              [_vm._m(1)]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "queries" },
          [
            _vm.loaded ? _c("queries-panel") : _vm._e(),
            _vm._v(" "),
            _vm.loaded ? _c("router-view") : _vm._e(),
          ],
          1
        ),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", { attrs: { "uk-icon": "icon: arrow-up" } }),
      _vm._v(' Выберите версию в разделе "версии"'),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("span", { attrs: { "uk-icon": "icon: lock" } }),
      _vm._v(" Текущая версия уже зафиксирована и защищена от записи"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="uk-margin-small-top">
    <div class="uk-text-large uk-margin-small-bottom">{{curBranch.BranchName}}</div>
    
    <branches-commit v-if="lastCommit !== null" :curCommitView="lastCommit"></branches-commit>
    
    <table class="uk-table uk-table-small uk-table-divider uk-table-hover">
      <tr>
        <th class="uk-table-shrink"></th>
        <th class="uk-table-shrink">#</th>
        <th class="uk-table-expand">Описание</th>
        <th>Дата</th>
      </tr>
      <tr v-for="commit in curBranch.Items" v-bind:class="{ 'uk-active': (commit.ModID === curModId) }" v-on:click="openCommit(commit)" style="cursor:pointer">
        <td>
          <button v-on:click.stop="selectCommit(commit)" class="uk-button uk-button-small" :class="{ 'uk-button-primary': commit.ModID === curModId, 'uk-button-secondary': commit.ModID !== curModId }"> Запросы </button>
        </td>
        <td>{{commit.ModID}}</td>
        <td><!--span v-for="proj in commit.Projects" class="uk-margin-small-right uk-badge">{{proj.Name}}</span--> {{commit.Message}} </td>
        <td  class="uk-table-shrink uk-text-nowrap">{{commit.Created.format("dd, L LT")}}</td>
      </tr>
    </table>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'branchesList',
  data () { return {
  }; },
  computed: mapState({
    curBranch: (state) => {
      let curBranch = _.cloneDeep(state.curBranch);
      _.each(curBranch.Items,(val)=>{
        val.Projects = _.filter(state.projects,{"ModID":val.ModID});
      })
      return curBranch;
    },
    lastCommit: (state)=>{
      if (!state.curBranch.Items.length) {
        return null;
      }
      return state.curBranch.Items[0];
    },    
    curModId: 'curModId'
  }),
  methods: {
    selectCommit(newCommit) {
      this.$store.commit('selectModId',newCommit.ModID);
      this.$router.push('/queries');
    },
    openCommit(newCommit) {
      this.$store.commit('selectCommit',newCommit);
    }
  }
}
</script>
 
<style>
</style>
//require("uikit/dist/css/uikit.css");
require("./uikit.scss");
import UIkit from "uikit";
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons);

import moment from 'moment';
moment.locale('ru');

import Vue from 'vue';
import Loading from './components/loading.vue';
import Auth from './components/auth.vue';
import Layout from './components/layout.vue';
import AuthModal from './components/authModal.vue';
import QueriesPanel from './components/queriesPanel.vue'
import QueriesStart from './components/queriesStart.vue'
import QueriesCategory from './components/queriesCategory.vue'
import QueriesEditor from './components/queriesEditor.vue'
import queriesEditorSource from './components/queriesEditorSource.vue'
import QueriesEditorTest from './components/queriesEditorTest.vue'
import QueriesEditorParams from './components/queriesEditorParams.vue'
import QueriesSelectBox from './components/queriesSelectBox.vue'
import Queries from './components/queries.vue'
import Application from './components/application.vue'
import branchesList from './components/branchesList.vue'
import branchesCommit from './components/branchesCommit.vue'
import branchesCommitApplied from './components/branchesCommitApplied.vue'
import newBranchModal from './components/newBranchModal.vue'
import queriesCategoryExportModal from './components/queriesCategoryExportModal.vue'
import queriesCategoryImportModal from './components/queriesCategoryImportModal.vue'
import Projects from './components/projects.vue'
import ProjectsEditor from './components/projectsEditor.vue'

import store from "./store.js";
import router from "./router.js";

Vue.use(require('vue-moment'));

Vue.component('loading', Loading);
Vue.component('auth', Auth);
Vue.component('layout', Layout);
Vue.component('modal-auth', AuthModal);
Vue.component('branches-list', branchesList);
Vue.component('branches-commit', branchesCommit);
Vue.component('branches-commit-applied', branchesCommitApplied);
Vue.component('new-branch-modal', newBranchModal);
Vue.component('queries-panel', QueriesPanel);
Vue.component('queries-start', QueriesStart);
Vue.component('queries-category-export-modal', queriesCategoryExportModal);
Vue.component('queries-category-import-modal', queriesCategoryImportModal);
Vue.component('queries-category', QueriesCategory);
Vue.component('queries-editor', QueriesEditor);
Vue.component('queries-editor-source', queriesEditorSource);
Vue.component('queries-editor-test', QueriesEditorTest);
Vue.component('queries-editor-params', QueriesEditorParams);
Vue.component('queries-select-box', QueriesSelectBox);
Vue.component('queries', Queries);
Vue.component('projects', Projects);
Vue.component('projects-editor', ProjectsEditor);

//Загружаем конфиг перед vue
store.dispatch('loadConfig').then(() => {
  console.log("config",store.state.config);
  let myVue = new Vue({
    el: '#application',
    render: h => h(Application),
    store,
	router
  });
});
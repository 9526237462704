<template>
<div class="queries-content">
  <loading v-if="loading"></loading>
  <div v-if="!loading && (query === null)" class="uk-alert-danger uk-margin-small-top" uk-alert>
    <p>
      <span uk-icon="icon: arrow-left"></span> Искомый запрос не найден в дереве
    </p>
  </div>
  <div v-if="!loading && (query !== null)">
    <h2 v-if="fullQuery.query_id != 0" class="uk-text-center">Редактирование запроса</h2>
    <h2 v-if="fullQuery.query_id == 0" class="uk-text-center">Создание запроса</h2>
    <ul class="uk-breadcrumb">
        <li v-for="cat in breadcrumb"><a @click.prevent="openCategory(cat)" href="#">{{cat.Name}}</a></li>
        <li><span>{{fullQuery.query_name}}</span></li>
    </ul>
    <div class="uk-grid-small uk-margin-small-top" uk-grid>
      <div class="uk-width-1-2">
        <div>
          <input v-model="fullQuery.query_name" class="uk-input uk-width-1-1" type="text" placeholder="Имя запроса" />
        </div>
        <div class="uk-margin-small-top">
          <textarea v-model="fullQuery.query_descr" class="uk-textarea uk-width-1-1" style="height: 100px" placeholder="Описание запроса"></textarea>
        </div>      
      </div>
      <div class="uk-width-1-2">
        <div>
          <button v-on:click="autoParams" class="uk-button uk-button-secondary uk-width-1-1">Авто параметры</button>
        </div>
        <div class="uk-margin-small-top">
          <queries-editor-params :params="fullQuery.query_params"></queries-editor-params>
        </div>
        <div class="uk-margin-small-top">
          <button class="uk-button uk-button-secondary uk-width-1-1" v-on:click="showSource = !showSource">Исходный код</button>
        </div>
        <queries-editor-source v-if="showSource" :query="fullQuery"></queries-editor-source>
      </div>
    </div>
    <div class="uk-margin-small-top">
      <textarea v-model="fullQuery.query_text" class="uk-textarea uk-width-1-1" style="height: 300px" placeholder="Содержимое запроса"></textarea>
    </div>  

    <div v-if="saveError != ''" class="uk-alert-danger uk-margin-small-top" uk-alert>
      <p v-html="saveError"></p>
    </div>    
    
    <div class="uk-text-center uk-margin-top">
      <button v-if="!saving" v-on:click="save()" class="uk-button uk-button-large uk-button-primary">Сохранить</button>
      <button v-if="saving" class="uk-button uk-button-large uk-button-primary" disabled>Сохранение</button>
    </div>
    
    <hr/>
    
    <queries-editor-test v-if="fullQuery.query_id != 0" v-bind:query="fullQuery"></queries-editor-test>
  </div>
</div>
</template> 
<script>
import sqlParser from "../sqlParser.js";
import carabiAdmin from "../carabiAdmin.js";

function nl2br (str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
        return '';
    }
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

export default {
  name: 'queriesEditor',
  data () { return {
    "loading" : true,
    "saving" : false,
    "saveError" : "",
    "showSource" : false,
    "fullQuery" : {
      query_id : 0,
      query_name : "",
      query_descr : "",
      query_text : "",
      query_params : []
    }
  }; },
  computed: {
    query() {
      return this.$store.state.curQuery;
    },
    curModId() {
      return this.$store.state.curModId;
    },
    breadcrumb() {
      return this.$store.getters.objectPath(this.fullQuery.category_id);
    }
  },
  watch: {
    "query": function(newQuery) {
      console.log('query newQuery',newQuery);
      this.reload();
    },
    $route: function() {
       this.initQuery(this.$route.name,this.$route.params.id);
    }
  },  
  methods : {
    openCategory(cat) {
      this.$router.push('/queries/category/'+cat.ObjectID);
    },  
    reload() {
      let self = this;
      if (this.query === null) {
        return;
      }
      if (this.query.query_id != 0) {
        //загрузить запрос
        self.loading = true;
        carabiAdmin.getQuery(this.curModId,this.query.Name).then((fullQuery)=>{        
          self.fullQuery = fullQuery;
          self.loading = false;
          console.log('fullQuery',fullQuery);
        });    
      } else {
        //Создать новый
        self.fullQuery = {
          category_id: this.query.category_id,
          query_descr: "",
          query_id: 0,
          query_name: "",
          query_params: [],
          query_text: "",
          query_type: "sql"
        };
        self.loading = false;
      }
    },
    initQuery(name,id) {
      if (name == "new-query") {
        //Создать новый
        this.$store.commit('selectQuery',{
          query_id : 0,
          category_id : id
        });
      } else {
        //загрузить запрос
        let newQuery = this.$store.getters.objectById(id);
        this.$store.commit('selectQuery',newQuery);
        if (newQuery === null) {
          this.loading = false;
        }
      }
    },
    autoParams() {
      let newParams = sqlParser.scanParametersList(this.fullQuery.query_text);
      console.log("newParams",newParams);
      sqlParser.mergeParameters(this.fullQuery.query_params,newParams);
    },
    async save() {

      this.saveError = "";
      this.saving = true;
      console.log("save",this.fullQuery);
      let newQueryId = null;
      try {
        newQueryId = await carabiAdmin.setQuery(this.curModId,this.fullQuery);
        this.saving = false;
      } catch (err) {
        console.log("setQuery err",err);
        this.saveError = nl2br(err.Message);
        this.saving = false;
        return;
      }
      
      this.$store.commit('replaceQuery',{ fullQuery:this.fullQuery, newQueryID:newQueryId });
      this.$router.push('/queries/query/'+newQueryId);
      
      /*
      //полная перезагрузка дерева
      this.$store.commit('setObjects',[]);
      //await this.$store.dispatch('loadQueries');
      */
    }
  },
  beforeMount () {
    this.initQuery(this.$route.name,this.$route.params.id);
  },
  mounted () {
  }
}
</script>
 
<style>
</style>
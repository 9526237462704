<template>
<table class="uk-table uk-table-small uk-table-divider">
  <tr v-for="(param, i) in params">
    <td>
      <input v-model="param.name" class="uk-input uk-form-small uk-width-1-1" type="text"/>
    <td>
      <select v-model="param.isInOrOut" class="uk-select uk-form-small">
        <option v-for="option in paramInOut" :selected="param.isInOrOut == option">{{option}}</option>
      </select>  
    </td>
    <td>
      <select v-model="param.type" class="uk-select uk-form-small">
        <option></option>
        <option v-for="option in paramTypes" :selected="param.type == option">{{option}}</option>
      </select>
    </td>
    <td>
      <a v-on:click.prevent="delParam(i)" href="#" uk-icon="icon: close"></a>
    </td>
  </tr>
  <tr>
    <td colspan="4" class="uk-text-center">
      <button v-on:click="addParam()" class="uk-button uk-button-small uk-button-primary">+ параметр</button>
    </td>
  </tr>
</table>
</template> 
<script>
export default {
  name: 'queriesEditorParams',
  data () { return {
    paramInOut : ['in','out','inout'],
    paramTypes : ["cursor",'number','varchar','date','clob','clob_as_varchar','clob_as_cursor']
  }; },
  props: {
    'params' : null
  },
  computed: {
  },
  methods : {
    addParam() {
      this.params.push({
        "name" : "",
        "isInOrOut" : "in",
        "type" : "varchar"
      });
    },
    delParam(i) {
      this.params.splice(i,1);
    }
  },
  mounted () {
  }
}
</script>
 
<style>
</style>
<template>
  <div class="branches">
    <!--div v-if="loading" class="branches-loader uk-text-center uk-padding">
      <div uk-spinner="ratio: 2"></div>
      <p>Загрузка...</p>
    </div-->

    <div v-if="projects !== null" class="projects-panel">
      <ul class="uk-nav uk-nav-default uk-margin-small-top uk-margin-small-left uk-margin-small-right">
          <li v-bind:class="{ 'uk-active': (curProject === null) }">
            <a v-on:click.prevent="changeProject(null)" href="#">
              <span class="uk-margin-small-right" uk-icon="icon: plus" />
              <strong>Новый проект</strong>
            </a>
          </li>
          <li v-for="proj in projects" :key="proj.sysname" v-bind:class="{ 'uk-active': (proj.sysname == curProjectId) }">
            <a v-on:click.prevent="changeProject(proj)" href="#">
              <span class="uk-margin-small-right" uk-icon="icon: database" />
              <strong>{{proj.sysname}}</strong>
            </a>
          </li>
      </ul>
    </div>
    <div v-if="projects !== null" class="branches-content">
      <projects-editor/>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'projects',
  data () { return {
  }; },
  computed: mapState({
    projects:'projects',
    curProject:'curProject',
    curProjectId: (state) => {
      return (state.curProject !== null) ?state.curProject.sysname :null;
    }
  }),
  methods: {
    changeProject(newProject) {
      this.$store.commit('selectProject',newProject);
    }
  },
  beforeMount () {
  }
}
</script>

<style>
.projects {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
}
.projects-panel {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  overflow-y: scroll;
}
.projects-content {
    flex-grow: 1;
    overflow-y: scroll;
    padding: 0 10px;
}
</style>

<template>
	<div class="queries-content">
    <div class="uk-alert-primary uk-margin-small-top" uk-alert>
      <p>
        <span uk-icon="icon: arrow-left"></span> Выберите категорию или запрос из дерева<br>
      </p>
    </div>
    <p>
          Ветка: {{curBranch.BranchName}} ({{curBranch.BranchDescr}})<br>
          Версия: {{curMod.ModID}} от {{curMod.Created.format("L LT")}}
    </p>
	</div>
</template> 
<script>
import UIkit from "uikit";

export default {
  name: 'queriesStart',
  data () { return {
  }; },
  computed: {
    "curMod" : function() {
      return this.$store.getters.modById(this.$store.state.curModId);
    },
    "curBranch" : function() {
      return this.$store.getters.branchById(this.curMod.BranchID);
    }    
  },
  beforeRouteUpdate (to, from, next) {	
    this.$store.commit('selectedClear');
    next();
  },  
  beforeMount () {
    this.$store.commit('selectedClear');
  } 
}

//this.$store.commit('selectedClear');
</script>
 
<style>
</style>
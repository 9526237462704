<template>
  <div ref="modal" uk-modal>
      <div class="uk-modal-dialog">
          <button class="uk-modal-close-default" type="button" uk-close></button>
          <div class="uk-modal-header">
              <h2 class="uk-modal-title">Авторизация</h2>
          </div>
          <div class="uk-modal-body">
              <form v-on:submit.prevent="submit">
                <div>
                  <select v-model="project" class="uk-select">
                    <option value="">Выберите проект</option>
                    <option v-for="prj in projects" :value="prj.sysname">{{prj.sysname}} ({{prj.name}})</option>
                  </select>
                </div>
                <div class="uk-margin-small-top">
                  <input v-model="login" class="uk-input" type="text" placeholder="Логин (если пусто - гостевой вход)">
                </div>
                <div class="uk-margin-small-top">
                  <input v-model="password" class="uk-input" type="password" placeholder="Пароль">
                </div>
                <div class="uk-margin-small-top uk-text-center">
                  <div v-if="error">{{error}}</div>
                </div>
              </form>
          </div>
          <div class="uk-modal-footer uk-text-right">
              <button class="uk-button uk-button-default uk-modal-close" type="button">Отмена</button>
              <button v-on:click="submit" v-if="!loading" class="uk-button uk-button-primary" type="button">Вход</button>
              <button v-if="loading" class="uk-button uk-button-primary" type="button" disabled>Загрузка</button>
          </div>
      </div>
  </div>
</template>
 
<script>
import UIkit from "uikit";

export default {
  name: 'authModal',
  data() { return {
    project : "",
    login : "",
    password: "",
    loading: false,
    error: ""
  }},
  computed: {
    projects() {
      return this.$store.state.projects;
    }
  },
  props: {
  },
  methods : {
    show() {
      UIkit.modal(this.$refs.modal).show();
    },
    hide() {
      UIkit.modal(this.$refs.modal).hide();
    },
    submit() {
      this.loading = true;
      this.$emit('auth',{"project":this.project,"login":this.login,"password":this.password});
    },
    complete(error) {
      this.error = error;
      this.loading = false;
    }
  },
  beforeMount () {
  }
}
</script>

<style>
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.queryIds !== null || _vm.category !== null
    ? _c(
        "div",
        { staticClass: "uk-alert-warning", attrs: { "uk-alert": "" } },
        [
          _vm.queryIds !== null
            ? _c("p", [
                _vm._v(
                  "Выбраны запросы (" +
                    _vm._s(_vm.queryIds.length) +
                    ' шт) для перемещения. Выберите новую категорию и нажмите "Подтвердить".'
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.category !== null
            ? _c("p", [
                _vm._v(
                  'Выбрана категория "' +
                    _vm._s(_vm.category.Name) +
                    '" для перемещения. Выберите новую родительскую категорию и нажмите "Подтвердить".'
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "uk-button uk-button-small uk-button-primary uk-button-small uk-margin-small-right uk-margin-small-bottom",
              on: {
                click: function ($event) {
                  return _vm.confirm()
                },
              },
            },
            [_vm._v("\n      Подтвердить\n    ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "uk-button uk-button-small uk-button-primary uk-button-small uk-margin-small-right uk-margin-small-bottom",
              on: {
                click: function ($event) {
                  return _vm.cancel()
                },
              },
            },
            [_vm._v("\n      Отмена\n    ")]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
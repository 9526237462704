<template>
    <div v-if="curModId === null" class="uk-alert-primary uk-margin-small-top" uk-alert>
      <p><span uk-icon="icon: arrow-up"></span> Выберите версию в разделе "версии"</p>
    </div>
    <loading v-else-if="!loaded"></loading>
    <div v-else class="queries-cont">
      <div v-if="curMod.IsApplied" class="uk-alert-warning uk-margin-remove" uk-alert>
        <p><span uk-icon="icon: lock"></span> Текущая версия уже зафиксирована и защищена от записи</p>
      </div>
      <div class="queries">
          <queries-panel v-if="loaded"></queries-panel>
          <router-view v-if="loaded"></router-view>
          <!--template>
            <queries-category v-if='mode == "category"'></queries-category>
            <queries-editor v-if='mode == "query"'></queries-editor>
          </template-->
      </div>
    </div>
</template>

<script>
import carabiAdmin from "../carabiAdmin.js";

export default {
  name: 'queries',
  data () { return {
  }; },
  computed: {
    loaded() {
      return (this.$store.state.objectTree.length != 0);
    },
    curMod() {
      return this.$store.getters.modById(this.curModId);
    },
    curModId() {
      return this.$store.state.curModId;
    }
  },
  watch: {
    curModId:function(){
      console.log('watch curModId',this.curModId,this.loaded);
      if (this.curModId !== null) {
        this.$store.commit('setObjects',[]);
        this.$store.dispatch('loadQueries');
      }
    }
  },
  created() {
    if (!this.$store.state.isDeveloper) {
      this.$router.push('/')
    }
  },
  beforeMount () {
    if ((!this.loaded) && (this.curModId !== null)) {
      this.$store.commit('setObjects',[]);
      this.$store.dispatch('loadQueries');
    }
  }
}
</script>

<style>
.queries-cont {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}
.queries {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    max-width: 100vw;
}
.queries-loader {
    flex-grow: 1;
}
.queries-content {
    flex-grow: 1;
    overflow-y: scroll;
    padding: 0 10px;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "uk-margin-small-top" },
    [
      _c("div", { staticClass: "uk-text-large uk-margin-small-bottom" }, [
        _vm._v(_vm._s(_vm.curBranch.BranchName)),
      ]),
      _vm._v(" "),
      _vm.lastCommit !== null
        ? _c("branches-commit", { attrs: { curCommitView: _vm.lastCommit } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "table",
        {
          staticClass:
            "uk-table uk-table-small uk-table-divider uk-table-hover",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.curBranch.Items, function (commit) {
            return _c(
              "tr",
              {
                class: { "uk-active": commit.ModID === _vm.curModId },
                staticStyle: { cursor: "pointer" },
                on: {
                  click: function ($event) {
                    return _vm.openCommit(commit)
                  },
                },
              },
              [
                _c("td", [
                  _c(
                    "button",
                    {
                      staticClass: "uk-button uk-button-small",
                      class: {
                        "uk-button-primary": commit.ModID === _vm.curModId,
                        "uk-button-secondary": commit.ModID !== _vm.curModId,
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.selectCommit(commit)
                        },
                      },
                    },
                    [_vm._v(" Запросы ")]
                  ),
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(commit.ModID))]),
                _vm._v(" "),
                _c("td", [_vm._v(" " + _vm._s(commit.Message) + " ")]),
                _vm._v(" "),
                _c("td", { staticClass: "uk-table-shrink uk-text-nowrap" }, [
                  _vm._v(_vm._s(commit.Created.format("dd, L LT"))),
                ]),
              ]
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", { staticClass: "uk-table-shrink" }),
      _vm._v(" "),
      _c("th", { staticClass: "uk-table-shrink" }, [_vm._v("#")]),
      _vm._v(" "),
      _c("th", { staticClass: "uk-table-expand" }, [_vm._v("Описание")]),
      _vm._v(" "),
      _c("th", [_vm._v("Дата")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "uk-margin-top" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("ul", { staticClass: "uk-switcher" }, [
      _c("li", [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.sourcePhp,
              expression: "sourcePhp",
            },
          ],
          staticClass: "uk-textarea uk-width-1-1",
          staticStyle: { height: "400px" },
          attrs: { readonly: "" },
          domProps: { value: _vm.sourcePhp },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.sourcePhp = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.sourceJs,
              expression: "sourceJs",
            },
          ],
          staticClass: "uk-textarea uk-width-1-1",
          staticStyle: { height: "400px" },
          attrs: { readonly: "" },
          domProps: { value: _vm.sourceJs },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.sourceJs = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.sourceJava,
              expression: "sourceJava",
            },
          ],
          staticClass: "uk-textarea uk-width-1-1",
          staticStyle: { height: "400px" },
          attrs: { readonly: "" },
          domProps: { value: _vm.sourceJava },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.sourceJava = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.sourceCSh,
              expression: "sourceCSh",
            },
          ],
          staticClass: "uk-textarea uk-width-1-1",
          staticStyle: { height: "400px" },
          attrs: { readonly: "" },
          domProps: { value: _vm.sourceCSh },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.sourceCSh = $event.target.value
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("li", [
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.sourcePG,
              expression: "sourcePG",
            },
          ],
          staticClass: "uk-textarea uk-width-1-1",
          staticStyle: { height: "400px" },
          attrs: { readonly: "" },
          domProps: { value: _vm.sourcePG },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.sourcePG = $event.target.value
            },
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", { attrs: { "uk-tab": "", "uk-switcher": "" } }, [
      _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v("PHP")])]),
      _vm._v(" "),
      _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v("JS")])]),
      _vm._v(" "),
      _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v("JAVA")])]),
      _vm._v(" "),
      _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v("C#")])]),
      _vm._v(" "),
      _c("li", [_c("a", { attrs: { href: "#" } }, [_vm._v("PG")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
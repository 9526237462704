<template>
  <div class="uk-margin-small-top">
    <div class="uk-text-large uk-margin-small-bottom">
      Версия {{curCommitView.ModID}} от {{curCommitView.Created.format("L LT")}}
    </div>    
    <div>
      <button v-on:click.prevent="goBack()" class="uk-button uk-button-default uk-button-small"><span uk-icon="icon: arrow-left"></span> Назад</button>
      <button v-on:click.stop="selectCommit()" class="uk-button uk-button-small" :class="{ 'uk-button-primary': curCommitView.ModID === curModId, 'uk-button-secondary': curCommitView.ModID !== curModId }"> Запросы </button>
      <button v-on:click.stop="setDefault()" class="uk-button uk-button-small uk-button-secondary"> Установить По Умолчанию </button>      
      <!--div class="uk-inline">
          <button class="uk-button uk-button-small uk-button-secondary" type="button">Назначить проекту</button>
          <div uk-dropdown>
              <ul class="uk-nav uk-dropdown-nav">
                class="uk-active"<li v-for="prj in projects"><a @click.prevent="setProject(prj.ProjectID)" href="#">
                  <span v-if="prj.ModID == curCommitView.ModID" uk-icon="check"></span>
                  {{prj.Name}}
                </a></li>
              </ul>
          </div>
      </div-->      
      <button v-on:click="newBranch()" class="uk-button uk-button-small uk-button-secondary"> Новая ветка </button>
    </div>
    <div class="uk-margin-small-top">
      {{curCommitView.Message}}
    </div>

    <new-branch-modal ref="branchModal" v-on:complete="newBranchComplete"></new-branch-modal>

  </div>
</template> 
<script>
import UIkit from "uikit";
import carabiAdmin from "../carabiAdmin.js";
import { mapState } from 'vuex';

export default {
  name: 'branchesCommitApplied',
  data () { return {
  }; },
  computed: mapState({
    curBranchID: state => state.curBranch.BranchID,
    curCommitView: 'curCommitView',
    curModId: 'curModId',
    projects: 'projects'
  }),
  methods: {
    goBack() {
      this.$store.commit('selectCommit',null);
    },
    selectCommit() {
      this.$store.commit('selectModId',this.curCommitView.ModID);
      this.$router.push('/queries');
    },
    newBranch() {
      this.$refs.branchModal.show();
    },
    newBranchComplete(result) {
      this.$store.commit('setBranches',null);
      this.$store.dispatch('loadBranches');
    },
    setProject(projectId) {
      let newProjects = _.cloneDeep(this.projects);
      _.each(newProjects,(val)=>{ 
        if (val.ProjectID == projectId) {
          val.ModID = this.curCommitView.ModID
        }
      });
      this.$store.commit('setProjects',newProjects);
    },
    async setDefault() {
      await carabiAdmin.setDefaultMod(this.curBranchID,this.curCommitView.ModID);
      UIkit.modal.alert('Модификация '+this.curCommitView.ModID+' задана всем клиентам!');
    }
  }
}
</script>
 
<style>
</style>
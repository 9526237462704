<template>
<div class=" uk-margin-top">
  <ul uk-tab uk-switcher>
      <li><a href="#">PHP</a></li>
      <li><a href="#">JS</a></li>
      <li><a href="#">JAVA</a></li>
      <li><a href="#">C#</a></li>
      <li><a href="#">PG</a></li>
  </ul>
  <ul class="uk-switcher">
      <li>
        <textarea v-model="sourcePhp" class="uk-textarea uk-width-1-1" readonly style="height: 400px"></textarea>
      </li>
      <li>
        <textarea v-model="sourceJs" class="uk-textarea uk-width-1-1" readonly style="height: 400px"></textarea>
      </li>
      <li>
        <textarea v-model="sourceJava" class="uk-textarea uk-width-1-1" readonly style="height: 400px"></textarea>
      </li>
      <li>
        <textarea v-model="sourceCSh" class="uk-textarea uk-width-1-1" readonly style="height: 400px"></textarea>
      </li>
      <li>
        <textarea v-model="sourcePG" class="uk-textarea uk-width-1-1" readonly style="height: 400px"></textarea>
      </li>
  </ul>
</div>
</template> 
<script>
const pgTypeMap = {
  "cursor": "refcursor",
  "number": "bigint",
  "varchar": "text",
  "date": "timestamp",
  "clob": "text",
  "clob_as_varchar": "text",
  "clob_as_cursor": "text"
}

export default {
  name: 'queriesEditorSource',
  data () { return {
  }; },
  props: {
    'query' : null,
  },
  computed: {    
    queryParamsIn() {
      let result = [];
        _.each(this.query.query_params,(param)=>{
          if (param.isInOrOut == "in") {
            result.push(param);
          }
      });
      return result;
    },
    sourcePhp() {
      let result = "";
      result += '$Q->select( "'+this.query.query_name+'", [\n';
      _.each(this.queryParamsIn,(param,i)=>{
        result += '\t"'+param.name+'" => ""';
        if (i < this.queryParamsIn.length-1) {
          result += ',';
        }
        result += ' //'+param.type+'\n';
      });
      result += '], 100);';
      return result;
    },
    sourceJs() {
      let result = "";
      result += 'let result = await carabi.select( "'+this.query.query_name+'", {\n';
      _.each(this.queryParamsIn,(param,i)=>{
        result += '\t'+param.name+' : ""';
        if (i < this.queryParamsIn.length-1) {
          result += ',';
        }
        result += ' //'+param.type+'\n';
      });
      result += '}, 100);';
      return result;    
    },
    sourceJava() {
      let result = "";
      result += 'QueryTask query = new QueryTask("'+this.query.query_name+'",\n';
      result += '\tnew QueryParamList(\n';
      let paramsStr = []
      this.queryParamsIn.forEach((param,i)=>{
        result += '\t\tnew QueryParam("'+param.name+'","")';
        if (i < this.queryParamsIn.length-1) {
          result += ',';
        }
        result += ' //'+param.type+'\n';
      })
      result += '\t),\n\t100,\n\tnull\n);\n';
      result += 'conn.service.startTask(query);\n';
      return result;    
    },    
    sourceCSh() {
      let result = "";
      result += 'public class '+this.query.query_name+'\n\t{\n';
      _.each(this.queryParamsIn,(param,i)=>{
        result += '\t\tpublic string '+param.name+' { get; set; }\n';
      });
      result += '\t}';
      return result;    
    },
    sourcePG() {
      let funName = "pub_"+this.query.query_name.toLowerCase()
      let retType = "void"
      let declare = ""
      let body = ""
      let args = []
      let outArgs = []
      for (let param of this.query.query_params) {
          if (param.isInOrOut == "in") {
            args.push(`\t"${param.name}$" ${pgTypeMap[param.type]}`);
          } else {
            retType = pgTypeMap[param.type]
            outArgs.push(`\tOUT "${param.name}$" ${pgTypeMap[param.type]}`);
            if (param.type === "cursor") {
              declare = `\tresult refcursor := 'result'\n`;
              body = `\topen result for select '1' as q;\n\t"${param.name}$" := result;`;
            }
          }
      }
      if (outArgs.length > 1) {
          retType = "record"
      }
      args = [...args, ...outArgs]

      let result = ""
      result += `CREATE OR REPLACE FUNCTION public.${funName}(\n`
      result += args.join(",\n")+"\n"
      result += `) RETURNS ${retType} LANGUAGE 'plpgsql' AS\n`
      result += `$BODY$\ndeclare\n${declare}begin\n${body}\nend;\n$BODY$;`
      return result
    }
  },
  methods : {},
  mounted () {}
}
</script>
/*
begin
  open result for select 'aaa' as qwe;
  "RESULT$" := result;
*/
<style>
</style>


<template>
  <div class="uk-text-center uk-padding">
    <div uk-spinner="ratio: 2"></div>
    <p>Загрузка...</p>
  </div>
</template> 
<script>
export default {
  name: 'loading'
}
</script>
 
<style>
</style>
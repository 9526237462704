var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "query_panel" } }, [
    _c("div", { staticClass: "panel_add" }, [
      _c("div", { staticClass: "uk-inline uk-width-1-1" }, [
        _c("a", {
          staticClass: "uk-form-icon uk-form-icon-flip",
          attrs: { href: "#", "uk-icon": "icon: close" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.searchClear.apply(null, arguments)
            },
          },
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.search,
              expression: "search",
            },
          ],
          ref: "search",
          staticClass: "uk-input",
          attrs: { type: "text", placeholder: "Поиск..." },
          domProps: { value: _vm.search },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.search = $event.target.value
            },
          },
        }),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { attrs: { id: "query_tree" } }),
    _vm._v(" "),
    _c("div", { staticClass: "panel_add" }, [
      _c(
        "button",
        {
          staticClass: "uk-button uk-button-secondary uk-width-1-1",
          on: {
            click: function ($event) {
              return _vm.addService()
            },
          },
        },
        [_vm._v("Новый сервис")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
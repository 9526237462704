<template>
  <div class="auth">
    <div class="inner uk-card uk-card-default uk-card-body">
      <h1 class="uk-text-center">
      <img src="favicon.png" style="height: 2.23125rem; margin-top: -0.5rem;" />
      Appserv3 Admin
      </h1>
      <form v-on:submit.prevent="submit">
        <div>
          <select v-model="serverIdx" class="uk-select">
            <option value="">Выберите сервер</option>
            <option v-bind:value="i" v-for="(srv, i) in servers" >{{srv.title}}</option>
          </select>
        </div>
        <div class="uk-margin-small-top">
          <input v-model="login" class="uk-input" type="text" placeholder="Логин">
        </div>
        <div class="uk-margin-small-top">
          <input v-model="password" class="uk-input" type="password" placeholder="Пароль">
        </div>
        <div class="uk-margin-small-top uk-text-center">
          <div v-if="error">{{error}}</div>
          <button v-if="!loading" class="uk-button uk-button-primary" type="submit">Вход</button>
          <button v-if="loading" class="uk-button uk-button-primary" disabled>Загрузка</button>
        </div>
      </form>
    </div>
  </div>
</template>
 
<script>
export default {
  name: 'auth',
  data() { return {
    serverIdx : 0,
    login : "root",
    password: "",
    loading: false,
    error: ""
  }},
  computed: {
    servers() {
      return this.$store.state.config.servers;
    },
    title() {
      return this.$store.state.curServer.title;
    }
  },
  methods : {
    submit() {
      if (this.serverIdx === '') {
        this.error = 'Сервер не выбран';
        return;
      }
      this.loading = true;
      this.$emit('auth',{"login":this.login,"password":this.password});
    },
    complete(error) {
      this.error = error;
      this.loading = false;
    }
  },
  watch: {
    serverIdx: function() {
      if (this.serverIdx === "") return; 
      this.$store.commit("setServer",this.servers[this.serverIdx])
    }  
  },
  beforeMount () {
    let curServ = this.$store.state.curServer;
    if (curServ !== null) {
      this.serverIdx = this.servers.indexOf(curServ);
    } else {
      this.serverIdx = "";
    }
  }
}
</script>

<style>
.auth {
  width: 100vw;
  height: 100vh;
  background: #eee;
}
.auth .inner {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);  
  
  margin: auto;
  padding: 10px;
  max-width: 400px;
}
</style>
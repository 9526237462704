<template>
	<div class="uk-text-center uk-padding">
    <h3>hello</h3>
	</div>
</template>
<script>
import UIkit from "uikit";

export default {
  name: 'Home',
  data () { return {
  }; },
  computed: {
    "curMod" : function() {
      return this.$store.getters.modById(this.$store.state.curModId);
    },
    "curBranch" : function() {
      return this.$store.getters.branchById(this.curMod.BranchID);
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.$store.commit('selectedClear');
    next();
  },
	created() {
		if (!this.$store.state.isDeveloper) {
			this.$router.push('/projects');
			return
		}

    let curModId = this.$store.state.curModId
    if (curModId) {
			this.$router.push('/queries');
		} else {
			this.$router.push('/branches');
		}
	},
  beforeMount () {
    this.$store.commit('selectedClear');
  }
}

//this.$store.commit('selectedClear');
</script>

<style>
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "modal-branch", "uk-modal": "" } }, [
    _c("div", { staticClass: "uk-modal-dialog" }, [
      _c("button", {
        staticClass: "uk-modal-close-default",
        attrs: { type: "button", "uk-close": "" },
      }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "uk-modal-body" }, [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              },
            },
          },
          [
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.branch_name,
                    expression: "branch_name",
                  },
                ],
                staticClass: "uk-input",
                attrs: { type: "text", placeholder: "Название ветки" },
                domProps: { value: _vm.branch_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.branch_name = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "uk-margin-small-top" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.branch_descr,
                    expression: "branch_descr",
                  },
                ],
                staticClass: "uk-input",
                attrs: { type: "text", placeholder: "Описание ветки" },
                domProps: { value: _vm.branch_descr },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.branch_descr = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "uk-margin-small-top uk-text-center" }, [
              _vm.error ? _c("div", [_vm._v(_vm._s(_vm.error))]) : _vm._e(),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-modal-footer uk-text-right" }, [
        _c(
          "button",
          {
            staticClass: "uk-button uk-button-default uk-modal-close",
            attrs: { type: "button" },
          },
          [_vm._v("Отмена")]
        ),
        _vm._v(" "),
        !_vm.loading
          ? _c(
              "button",
              {
                staticClass: "uk-button uk-button-primary",
                attrs: { type: "button" },
                on: { click: _vm.submit },
              },
              [_vm._v("Создать")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.loading
          ? _c(
              "button",
              {
                staticClass: "uk-button uk-button-primary",
                attrs: { type: "button", disabled: "" },
              },
              [_vm._v("Загрузка")]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "uk-modal-header" }, [
      _c("h2", { staticClass: "uk-modal-title" }, [_vm._v("Новая ветка")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "queries-content" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "\n          Ветка: " +
          _vm._s(_vm.curBranch.BranchName) +
          " (" +
          _vm._s(_vm.curBranch.BranchDescr) +
          ")"
      ),
      _c("br"),
      _vm._v(
        "\n          Версия: " +
          _vm._s(_vm.curMod.ModID) +
          " от " +
          _vm._s(_vm.curMod.Created.format("L LT")) +
          "\n    "
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "uk-alert-primary uk-margin-small-top",
        attrs: { "uk-alert": "" },
      },
      [
        _c("p", [
          _c("span", { attrs: { "uk-icon": "icon: arrow-left" } }),
          _vm._v(" Выберите категорию или запрос из дерева"),
          _c("br"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "uk-text-center uk-padding" }, [
      _c("div", { attrs: { "uk-spinner": "ratio: 2" } }),
      _vm._v(" "),
      _c("p", [_vm._v("Загрузка...")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
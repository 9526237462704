var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "uk-modal-container uk-flex-top",
      attrs: { "uk-modal": "" },
    },
    [
      _c("div", { staticClass: "uk-modal-dialog" }, [
        _c("button", {
          staticClass: "uk-modal-close-default",
          attrs: { type: "button", "uk-close": "" },
        }),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "uk-modal-body" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.content,
                expression: "content",
              },
            ],
            staticClass: "uk-textarea",
            staticStyle: { height: "70vh" },
            domProps: { value: _vm.content },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.content = $event.target.value
              },
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "uk-modal-header" }, [
      _c("h2", { staticClass: "uk-modal-title" }, [
        _vm._v("Экспорт категории"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
    <auth v-if="!authorized" ref="auth" v-on:auth="auth"></auth>
    <layout v-else v-on:logout="logout"></layout>
</template>

<script>
import carabiAdmin from "../carabiAdmin.js";
import Cookies from 'js-cookie';

export default {
  name: 'application',
  data() {
    return {
      "authorized" : carabiAdmin.token !== null
    };
  },
  computed: {
  },
  methods : {
    async auth(authData) {
      try {
        await carabiAdmin.auth(authData.login,authData.password)
        this.$store.commit('setUserTokenInfo',null);
        this.$refs.auth.complete("");
        this.authorized = true;
        let isDeveloper = (['root','master'].indexOf(authData.login) !== -1)
        this.$store.commit('setDeveloper',isDeveloper);
        this.$router.push('/');
      } catch (e) {
        this.$refs.auth.complete(error.Message);
      }
    },

    logout() {
      carabiAdmin.logout();
      this.$store.commit("logout");
      this.authorized = false;
    }
  },
  created() {
    //console.log("carabiAdmin",carabiAdmin)
  },
  beforeRouteUpdate (to, from, next) {
  },
  beforeMount () {
    document.getElementById("loader").setAttribute("hidden","hidden");

    let serverAddr = Cookies.get("server");
    if (serverAddr !== undefined) {
      let serv = _.filter(this.$store.state.config.servers,{"address":serverAddr});
      if (serv.length > 0) {
        this.$store.commit('setServer',serv[0]);
      }
    }

    let isDeveloper = Cookies.get("developer")?true:false;
    this.$store.commit('setDeveloper',isDeveloper);
  }
}
</script>

<style>
</style>

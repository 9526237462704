<template>
<div class="queries-content">

  <queries-select-box v-on:confirm="selectedConfirm()"></queries-select-box>

  <div class="uk-margin-small-top uk-margin">
    <input v-on:blur="rename()" v-model.trim="newName" class="uk-input uk-width-1-1 uk-form-large" type="text" placeholder="Заголовок категории">
  </div>
  <div>
    <button class="uk-button uk-button-small uk-margin-small-right uk-margin-small-bottom" v-for="cat in subcategories" v-on:click="openCategory(cat)">
      {{ cat.Name }}
    </button>
  </div>
  <div class="uk-flex">
    <div class="uk-margin-small-right">
      <strong class="uk-margin-small-bottom">Выделенное ({{checkedQueries.length}}):</strong><br>
      <button v-on:click="selectAll()" class="uk-button  uk-button-primary uk-button-small  uk-margin-small-right uk-margin-small-bottom" uk-tooltip="title: Выделить все; pos: top">
        <span uk-icon="check"></span>
      </button>
      <button :disabled="checkedQueries.length == 0" v-on:click="moveQueries()" class="uk-button  uk-button-primary uk-button-small  uk-margin-small-right uk-margin-small-bottom" uk-tooltip="title: Переместить выделенные запросы; pos: top">
        <span uk-icon="move"></span>
      </button>    
      <button :disabled="checkedQueries.length == 0" v-on:click="deleteQueries()" class="uk-button  uk-button-primary uk-button-small  uk-margin-small-right uk-margin-small-bottom" uk-tooltip="title: Удалить выделенные запросы; pos: top">
        <span uk-icon="minus"></span>
      </button>
    </div>
    <div class="">
      <strong v-if="category.TypeName == 'SERVICE'" class="uk-margin-small-bottom">Сервис:</strong>
      <strong v-if="category.TypeName != 'SERVICE'" class="uk-margin-small-bottom">Категория:</strong>
      <br>
      <button v-on:click="newQuery()" class="uk-button  uk-button-secondary uk-button-small  uk-margin-small-right uk-margin-small-bottom" uk-tooltip="title: Новый запрос; pos: top">
        <span uk-icon="plus"></span>
      </button>
      <button v-on:click="addCategory()" class="uk-button  uk-button-secondary uk-button-small  uk-margin-small-right uk-margin-small-bottom" uk-tooltip="title: Новая подкатегория; pos: top">
        <span uk-icon="plus-circle"></span>
      </button>
      <button v-on:click="deleteCategory()" class="uk-button  uk-button-secondary uk-button-small  uk-margin-small-right uk-margin-small-bottom" uk-tooltip="title: Удалить категорию; pos: top">
        <span uk-icon="minus-circle"></span>
      </button>
      <button v-if="category.TypeName != 'SERVICE'" v-on:click="moveCategory()" class="uk-button uk-button-small  uk-button-secondary  uk-margin-small-right uk-margin-small-bottom" uk-tooltip="title: Переместить категорию; pos: top">
        <span uk-icon="move"></span>
      </button>
      <button v-on:click="exportCategory()" class="uk-button  uk-button-secondary uk-button-small  uk-margin-small-right uk-margin-small-bottom" uk-tooltip="title: Экспорт; pos: top">
        <span uk-icon="push"></span>
      </button>
      <button v-on:click="importCategory()" class="uk-button  uk-button-secondary uk-button-small  uk-margin-small-right uk-margin-small-bottom" uk-tooltip="title: Импорт; pos: top">
        <span uk-icon="pull"></span>
      </button>
    </div>
  </div>
  
  <table class="uk-table uk-table-small uk-table-divider uk-table-hover">
    <tbody>
        <tr v-for="qry in subqueries">
            <td class="uk-table-shrink">
              <input class="uk-checkbox" type="checkbox" :value="qry.ObjectID" v-model="checkedQueries">
            </td>
            <td class="uk-table-shrink">
              <a v-on:click.prevent="openQuery(qry)" href="#"><strong>{{qry.Name}}</strong></a>
            </td>
            <td>
              {{qry.Descr}}
            </td>
        </tr>
    </tbody>
  </table>
  <div class="uk-text-center">
    <button v-on:click="newQuery()" class="uk-button uk-button-primary uk-button-small uk-margin-small-right uk-margin-small-bottom">
      <span uk-icon="plus"></span> Новый запрос
    </button>  
  </div>
  
  <queries-category-export-modal ref="export"></queries-category-export-modal>
  <queries-category-import-modal ref="import" v-on:complete="reload"></queries-category-import-modal>
</div>
</template> 
<script>
import UIkit from "uikit";
import carabiAdmin from "../carabiAdmin.js";

export default {
  name: 'queriesCategory',
  data () { return {
    newName: "",
    checkedQueries: [],
  }; },
  props: {
  },
  computed: {
    category() {
      return this.$store.state.curCategory;
    },
    subqueries() {
      return _.filter(this.category.Items,{"TypeName":"QUERY"});
    },
    subcategories() {
      return _.filter(this.category.Items,{"TypeName":"CATEGORY"});
    },
    curModId() {
      return this.$store.state.curModId;
    }
  },
  watch: {
    "category" : function(newCategory) {
      this.newName = newCategory.Name;
      this.checkedQueries = [];
    }
  },
  methods : {
    openCategory(cat) {
      this.$router.push('/queries/category/'+cat.ObjectID);
    },
    openQuery(query) {
      this.$router.push('/queries/query/'+query.ObjectID);
    },
    newQuery() {
      this.$router.push('/queries/new-query/'+this.category.ObjectID);
    },    
    selectAll() {
      this.checkedQueries = [];
      _.each(this.subqueries,(query)=>{
        this.checkedQueries.push(query.ObjectID);
      });
    },
    async addCategory() {
      this.$store.commit('setObjects',[]);
      let result = await carabiAdmin.addCategory(this.curModId,this.category.ObjectID,"Новая категория");
      console.log("addCategory",result);
      await this.$store.dispatch('loadQueries');
      this.$router.push('/queries/category/'+result.ObjectID);
    },
    moveCategory() {
      this.$store.commit('moveCategory',this.category);
    },
    moveQueries() {
      this.$store.commit('moveQueries',this.checkedQueries);
      this.checkedQueries = [];
    },
    async deleteCategory() {
      if ((this.subqueries.length > 0) || (this.subcategories.length > 0)) {
        UIkit.notification("Категория не пустая!", {status:'danger'})
        return;
      }
      this.$store.commit('setObjects',[]);
      await carabiAdmin.deleteObject(this.curModId,this.category.ObjectID);
      this.$store.commit('selectCategory',null);
      await this.$store.dispatch('loadQueries');
      this.$router.push('/queries');
    },
    async deleteQueries() {
      await UIkit.modal.confirm('Удалить выранные запросы ('+this.checkedQueries.length+' шт) ?');
      this.$store.commit('setObjects',[]);
      await carabiAdmin.deleteObjectIds(this.curModId,this.checkedQueries);
      await this.$store.dispatch('loadQueries');
    },
    async selectedConfirm() {
      let fromQueryIds = this.$store.state.selectedItems.queryIds;
      let fromCategory = this.$store.state.selectedItems.category;
      let toCategory = this.category;
      this.$store.commit('setObjects',[]);
      if (fromQueryIds !== null) {
        console.log("selectedConfirm",fromQueryIds,toCategory);
        await carabiAdmin.setParentIds(this.curModId,fromQueryIds,this.category.ObjectID);
      }
      if (fromCategory !== null) {
        console.log("selectedConfirm",fromCategory,toCategory);
        await carabiAdmin.setParent(this.curModId,fromCategory.ObjectID,this.category.ObjectID);
      }
      this.$store.commit('selectedClear');
      await this.$store.dispatch('loadQueries');
      this.$router.push('/queries/category/'+toCategory.ObjectID);
    },
    async rename() {
      if (this.newName == this.category.Name) return;
      this.$store.commit('setObjects',[]);
      let result = null;
      if (this.category.TypeName == "SERVICE") {
        result = await carabiAdmin.setObjectProperty(this.curModId,this.category.ObjectID,"SERVICE_NAME",this.newName);
      } else {
        result = await carabiAdmin.setObjectProperty(this.curModId,this.category.ObjectID,"CATEGORY_NAME",this.newName);
      }
      console.log("rename",result);
      await this.$store.dispatch('loadQueries');
      this.$router.push('/queries/category/'+result.ObjectID);
    },
    exportCategory() {
      this.$refs.export.show();
    },
    importCategory() {
      this.$refs.import.show();
    },
    async reload() {
      this.$store.commit('setObjects',[]);
      await this.$store.dispatch('loadQueries');
    },
    initCategory(catId) {
      let newCat = this.$store.getters.objectById(catId);
      console.log("initCategory",catId,newCat);
      this.$store.commit('selectCategory',newCat);
    }
  },
  beforeRouteUpdate(to, from, next) {	
    this.initCategory(to.params.id);
    next();
  },  
  beforeMount() {
    this.initCategory(this.$route.params.id);
  }
}
</script>
 
<style>
</style>
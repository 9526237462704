<template>
    <div class="appcontainer">
        <nav class="uk-navbar-container" uk-navbar style="min-height: 40px">
            <div class="uk-navbar-left">
                <div class="uk-navbar-item uk-logo">
                  <img src="favicon.png" style="height: 35px;" />
                </div>
                <ul class="uk-navbar-nav">
                    <li v-if="isDeveloper" v-bind:class="{ 'uk-active': (curRoute == '/branches') }"><a href="#/branches">Версии</a></li>
                    <li v-if="isDeveloper" v-bind:class="{ 'uk-active': (curRoute == '/queries') }"><a href="#/queries">Запросы</a></li>
                    <li v-bind:class="{ 'uk-active': (curRoute == '/projects') }"><a href="#/projects">Проекты</a></li>
                    <li v-bind:class="{ 'uk-active': (curRoute == '/users') }"><a href="#/users">Пользователи</a></li>
                    <!--li><a href=""></a></li-->
                </ul>
            </div>
            <div class="uk-navbar-right">
                <div class="uk-navbar-item uk-logo">{{title}}</div>
                <div class="uk-navbar-item">
                    <button v-on:click="logout" class="uk-button uk-button-small uk-button-default"><span uk-icon="icon: sign-out"></span> Выход</button>
                </div>
            </div>
        </nav>
        <loading v-if="loading"></loading>
        <router-view v-else></router-view>
    </div>
</template>

<script>
import carabiAdmin from "../carabiAdmin.js";
import Cookies from 'js-cookie';

export default {
  name: 'layout',
  data() {
    return {
      loading: true
    };
  },
  computed: {
    title() {
      return this.$store.state.curServer.title;
    },
    curRoute() {
      let routes = this.$route.matched;
      if (routes.length == 0) return "";
      return this.$route.matched[0].path;
    },
    isDeveloper() {
      return this.$store.state.isDeveloper
    }
  },
  methods : {
    logout() {
      this.$emit("logout");
    }
  },
  beforeRouteUpdate (to, from, next) {
  },
  async created () {
    await Promise.all([
      this.$store.dispatch('loadProjects'),
      this.$store.dispatch('loadBranches')
    ])

    let curBranchName = localStorage.getItem('curBranch');
    let curBranch = null
    console.log('curBranchName',curBranchName);

    if (curBranchName) {
      curBranch = this.$store.state.branches.find((val)=>(val.BranchName == curBranchName));
      console.log('curBranch',curBranch);
      if (curBranch) {
        console.log('curBranch',curBranch);
        this.$store.commit('selectBranch',curBranch);
      }
    }

    if (curBranch) {
      let curModId = null;
  		if (curBranch.Items.length) {
  			curModId = curBranch.Items[0].ModID;
  		}
      console.log('curModId',curModId);
      this.$store.commit('selectModId',curModId);
    }

    this.loading = false
  }
}
</script>

<style>
.appcontainer {
    display: flex;
    flex-direction: column;
    height: 100vh;
}
</style>

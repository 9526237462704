import Vue from 'vue';
import Vuex from 'vuex';
import moment from 'moment';
import carabiAdmin from "./carabiAdmin.js";
import async from 'async';
import Cookies from 'js-cookie';

Vue.use(Vuex);

//Обход всего дерева, в каждой нове вызывает callback
let treeTravel = (root,childrenName,callback) => {
  _.each(root,(val,i)=>{
    if (callback(val,i) === false) return false;
    if (val[childrenName] !== undefined) {
      let result = treeTravel(val[childrenName],childrenName,callback);
      if (result === false) return false;
    }
  });
};

//Обходит дерево, пока callback не вернет true, затем возвращает путь до этого true
let treeFindPath = (root,childrenName,callback) => {
  for (var i in root) {
    let val = root[i];
    //console.log('every',val);
    if (callback(val,i) === true) {
      return [val];
    };
    if (val[childrenName] !== undefined) {
      let result = treeFindPath(val[childrenName],childrenName,callback);
      if (result !== null) {
        result.unshift(val);
        return result;
      }
    }
  };
  return null;
};

let objectToFancy = (val) => {
  let newVal = {
    TypeName: val.TypeName,
    ObjectID: val.ObjectID,
    Descr: val.Descr,
    title : val.Name,
  };
  if (val.TypeName == "SERVICE") {
    newVal.key = 'c'+val.ObjectID;
    newVal.folder = true;
  }
  if (val.TypeName == "CATEGORY") {
    newVal.key = 'c'+val.ObjectID;
    newVal.folder = true;
  }
  if (val.TypeName == "QUERY") {
    newVal.key = 'q'+val.ObjectID;
    newVal.folder = false;
    newVal.Name = val.Name;
    newVal.title = "<strong>"+val.Name+"</strong><br>" + val.Descr;
  }
  return newVal;
}

let objectById = (state,objId) => {
  let result = null;
  treeTravel(state.objectTree,"Items",(obj,i)=>{
    if (obj.ObjectID == objId) {
      result = obj;
      return false;
    }
  });
  return result;
}

export default new Vuex.Store({
  state: {
    config: {},
    projects: [],
    branches: null,
    objectTree : [],
    userTokenInfo : null,

    curServer: null,
    curModId: null,
    isDeveloper: false,

    curBranch: null,
    curCommitView: null,
    curService: null,
    curCategory: null,
    curQuery: null,
    curProject: null,

    //Move interface
    selectedItems : {
      queryIds: null,
      category: null
    }
  },

  getters: {
    queryTree: (state) => {
      //Рекурсивная функция
      let convertTree = (Items) => {
        let result = [];
        _.each(Items,(val,i)=>{
          let newVal = objectToFancy(val);
          if (val.Items !== undefined) {
            newVal.children = convertTree(val.Items);
          }
          result.push(newVal);
        });
        return result;
      };

      let result = convertTree(state.objectTree);
      console.log("queryTree",result);
      return result;
    },

    searchQuery: (state) => (context) => {
      let searchResult = [];
      treeTravel(state.objectTree,"Items",(query,i)=>{
        if (query.TypeName != "QUERY") return;
        let searchStr = query.Name+' '+query.Descr;
        searchStr = searchStr.toLowerCase();
        if (searchStr.search(context.toLowerCase()) != -1) {
          searchResult.push(objectToFancy(query));
        }
      });
      console.log('searchQuery',context,searchResult);
      return searchResult;
    },

    objectById: (state) => (objId) => {
      return objectById(state,objId);
    },

    modById: (state) => (modId) => {
      let result = null;
      _.each(state.branches,(br)=>{
        _.each(br.Items,(mod)=>{
          if (mod.ModID == modId) {
            result = mod;
            return false;
          }
        });
        if (result !== null) return false;
      });
      return result;
    },

    branchById: (state) => (branchId) => {
      let result = _.filter(state.branches,{"BranchID":branchId});
      if (result.length > 0) {
        return result[0];
      }
      return null;
    },

    objectPath: (state) => (ObjectID) => {
      return treeFindPath(state.objectTree,"Items",(obj,i)=>{
        return (obj.ObjectID == ObjectID);
      });
    }

  },

  mutations: {
    setConfig(state, newConfig) {
      console.log('setConfig',newConfig)
      state.config = newConfig;
    },
    setUserTokenInfo(state, newInfo) {
      state.userTokenInfo = newInfo;
    },
    setServer(state, newServer) {
      Cookies.set('server',newServer.address,{expires:7,path:''});
      carabiAdmin.config = newServer;
      document.title = newServer.title;
      state.curServer = newServer;
    },
    setDeveloper(state,payload) {
      state.isDeveloper = payload
      Cookies.set('developer',payload?'1':'',{expires:7,path:''});
    },
    logout(state) {
      state.curModId = null;
      state.isDeveloper = false
      Cookies.remove('mod_id');
    },

    setObjects(state, newObjects) {
      state.objectTree = newObjects;
    },

    //Заменить запрос
    replaceQuery(state, {fullQuery, newQueryID} ) {
      let curCategory = objectById(state,fullQuery.category_id);
      if (fullQuery.query_id) {
        let idx = curCategory.Items.findIndex((obj)=>{
          return (obj.ObjectID == fullQuery.query_id);
        });
        if (idx != -1) {
          curCategory.Items.splice(idx,1);
        }
      }
      curCategory.Items.push({
        "Name":fullQuery.query_name,
        "Descr":fullQuery.query_descr,
        "ObjectID":newQueryID,
        "category_id":fullQuery.category_id,
        "QueryText":fullQuery.query_text,
        "TypeName":"QUERY",
        "QueryType":0,
        "Count":0,
        "paramList":{"Params":[]},
        "Items":[]
      })
    },

    setBranches(state, newBranches) {
      state.branches = newBranches;
      if (state.branches === null) return;

      _.each(newBranches,(val)=>{
        _.each(val.Items,(val2)=>{
          val2.Created = moment(val2.Created);
          if (!val2.IsApplied) {
            val2.Message = "Рабочая версия";
          }
        });
        val.Items.sort((left, right) => {
            return moment.utc(right.Created).diff(moment.utc(left.Created));
        });
      });

      if (state.curBranch !== null) {
        //let newBranch = _.find(state.branches,(br)=>{ return (br.BranchID == state.curBranch.BranchID); });
        console.log("newBranch",state.branches,state.curBranch.BranchID);
        let newBranch = _.filter(state.branches,{"BranchID":state.curBranch.BranchID});
        state.curBranch = (newBranch.length > 0)?newBranch[0]:null;
      }
      if (state.curCommitView !== null) {
        //let newCommit = _.find(state.curBranch.Items,(comm)=>{ return (comm.ModID == state.curCommitView.ModID); });
        if (state.curBranch !== null) {
          console.log("newCommit",state.curBranch.Items,state.curCommitView.ModID);
          let newBranch = _.filter(state.curBranch.Items,{"ModID":state.curCommitView.ModID});
          state.curCommitView = (newBranch.length > 0)?newBranch[0]:null;
        } else {
          state.curCommitView = null;
        }
      }
    },
    setProjects(state, newProjects) {
      state.projects = newProjects;
    },
    selectBranch(state, newBranch) {
      state.curCommitView = null;
      state.curBranch = newBranch;
    },
    selectCommit(state, newCommit) {
      state.curCommitView = newCommit;
    },
    selectModId(state, newModId) {
      state.curModId = parseInt(newModId);
      //Cookies.set('mod_id',state.curModId,{expires:7,path:''});
      state.objectTree = [];
      state.curQuery = null;
      state.curCategory = null;
      state.selectedItems.queryIds = null;
      state.selectedItems.category = null;
    },

    selectProject(state, newProject) {
      state.curProject = newProject;
    },

    selectCategory(state,newCat) {
      state.curQuery = null;
      state.curCategory = newCat;
    },
    selectQuery(state,newQuery) {
      state.curCategory = null;
      state.curQuery = newQuery;
    },

    //Move interface
    moveCategory(state, category) {
      state.selectedItems.queryIds = null;
      state.selectedItems.category = category;
    },
    moveQueries(state, queryIds) {
      state.selectedItems.queryIds = queryIds;
      state.selectedItems.category = null;
    },
    selectedClear(state) {
      state.selectedItems.queryIds = null;
      state.selectedItems.category = null;
    }
  },

  actions: {
    async loadConfig(context) {
      let response = await fetch("/config.json");
      let newConfig = await response.json();
      console.log("newConfig",newConfig);
      context.commit('setConfig',newConfig);
    },

    //Загрузка дерева запросов
    async loadQueries(context) {
      let objectTree = await carabiAdmin.serverHttp('admin/API/GetModData',{
        "mod_id" : context.state.curModId,
        "admin_token":carabiAdmin.token
      });
      context.commit('setObjects',objectTree.Items);
    },

    async loadBranches(context) {
      let branches = await carabiAdmin.getBranchModList();
      context.commit('setBranches',branches.Branches);
    },

    async loadProjects(context) {
      let projects = await carabiAdmin.getProjectsList();
      context.commit('setProjects',projects);
    },
  }
});

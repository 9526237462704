var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "appcontainer" },
    [
      _c(
        "nav",
        {
          staticClass: "uk-navbar-container",
          staticStyle: { "min-height": "40px" },
          attrs: { "uk-navbar": "" },
        },
        [
          _c("div", { staticClass: "uk-navbar-left" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("ul", { staticClass: "uk-navbar-nav" }, [
              _vm.isDeveloper
                ? _c(
                    "li",
                    { class: { "uk-active": _vm.curRoute == "/branches" } },
                    [
                      _c("a", { attrs: { href: "#/branches" } }, [
                        _vm._v("Версии"),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isDeveloper
                ? _c(
                    "li",
                    { class: { "uk-active": _vm.curRoute == "/queries" } },
                    [
                      _c("a", { attrs: { href: "#/queries" } }, [
                        _vm._v("Запросы"),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "li",
                { class: { "uk-active": _vm.curRoute == "/projects" } },
                [
                  _c("a", { attrs: { href: "#/projects" } }, [
                    _vm._v("Проекты"),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("li", { class: { "uk-active": _vm.curRoute == "/users" } }, [
                _c("a", { attrs: { href: "#/users" } }, [
                  _vm._v("Пользователи"),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-navbar-right" }, [
            _c("div", { staticClass: "uk-navbar-item uk-logo" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "uk-navbar-item" }, [
              _c(
                "button",
                {
                  staticClass: "uk-button uk-button-small uk-button-default",
                  on: { click: _vm.logout },
                },
                [
                  _c("span", { attrs: { "uk-icon": "icon: sign-out" } }),
                  _vm._v(" Выход"),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.loading ? _c("loading") : _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "uk-navbar-item uk-logo" }, [
      _c("img", {
        staticStyle: { height: "35px" },
        attrs: { src: "favicon.png" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "uk-margin-small-top" }, [
    _c("div", { staticClass: "uk-text-large uk-margin-small-bottom" }, [
      _vm._v(
        "\n      Рабочая версия " + _vm._s(_vm.curCommitView.ModID) + "\n    "
      ),
    ]),
    _vm._v(" "),
    _vm.fullpage
      ? _c("div", [
          _c(
            "button",
            {
              staticClass: "uk-button uk-button-default uk-button-small",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.goBack()
                },
              },
            },
            [
              _c("span", { attrs: { "uk-icon": "icon: arrow-left" } }),
              _vm._v(" Назад"),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "uk-button uk-button-small",
              class: {
                "uk-button-primary": _vm.curCommitView.ModID === _vm.curModId,
                "uk-button-secondary": _vm.curCommitView.ModID !== _vm.curModId,
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.selectCommit()
                },
              },
            },
            [_vm._v(" Запросы ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "uk-button uk-button-small uk-button-secondary",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.setDefault()
                },
              },
            },
            [_vm._v(" Установить По Умолчанию ")]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "uk-margin-small-top" }, [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.commitMessage,
            expression: "commitMessage",
          },
        ],
        staticClass: "uk-textarea uk-width-1-1",
        attrs: {
          disabled: _vm.commiting,
          rows: "3",
          placeholder: "Описание изменений...",
        },
        domProps: { value: _vm.commitMessage },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.commitMessage = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      !_vm.commiting
        ? _c(
            "button",
            {
              staticClass: "uk-button uk-button-primary uk-margin-small-top",
              on: {
                click: function ($event) {
                  return _vm.commit()
                },
              },
            },
            [_vm._v(" Зафиксировать ")]
          )
        : _c(
            "button",
            {
              staticClass: "uk-button uk-button-primary uk-margin-small-top",
              attrs: { disabled: "" },
            },
            [_vm._v(" Обрабатывается... ")]
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from './components/home.vue'
import Branches from './components/branches.vue'
import Queries from './components/queries.vue'
import QueriesStart from './components/queriesStart.vue'
import QueriesCategory from './components/queriesCategory.vue'
import QueriesEditor from './components/queriesEditor.vue'
import Projects from './components/projects.vue'
import Users from './components/users.vue'

Vue.use(VueRouter);

export default new VueRouter({
  //mode: 'history',
  routes: [{
    path: '',
    component: Home
  },{
    path: '/branches',
    component: Branches
  },{
    path: '/queries',
    component: Queries,
    children: [
      {
        path: '',
        component: QueriesStart
      },
      {
        path: 'category/:id',
        component: QueriesCategory
      },
      {
        path: 'query/:id',
        name: 'query',
        component: QueriesEditor
      },
      {
        path: 'new-query/:id',
        name: 'new-query',
        component: QueriesEditor
      }
    ]
  },{
    path: '/projects',
    component: Projects
  },{
    path: '/users',
    component: Users
  }]
});

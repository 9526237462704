<template>
  <div id="query_panel">
    <div class="panel_add">
        <div class="uk-inline uk-width-1-1">
            <a v-on:click.prevent="searchClear" class="uk-form-icon uk-form-icon-flip" href="#" uk-icon="icon: close"></a>
            <input v-model="search" ref="search" class="uk-input" type="text" placeholder="Поиск...">
        </div>
    </div>
    <div id="query_tree"></div>
    <div class="panel_add">
      <button v-on:click="addService()" class="uk-button uk-button-secondary uk-width-1-1">Новый сервис</button>
    </div>
  </div>
</template>
 
<script>
import 'jquery.fancytree/dist/skin-win8/ui.fancytree.css';  // CSS or LESS
import carabiAdmin from "../carabiAdmin.js";
import {createTree} from 'jquery.fancytree';

export default {
  name: 'queriesPanel',
  data () {
    return {
      search: "",
      rootNode: null
    }
  },
  computed: {
    queryTree() {
      return this.$store.getters.queryTree;
    },
    curCategory() {
      return this.$store.state.curCategory;
    },
    curQuery() {
      return this.$store.state.curQuery;
    },
    curModId() {
      return this.$store.state.curModId;
    }
  },
  methods : {
    searchClear() {
      this.search = '';
    },
    refreshTree() {
      console.log("refreshTree");
      this.rootNode.removeChildren();
      if (this.search.length >= 2) {
        this.rootNode.addChildren(this.$store.getters.searchQuery(this.search));
      } else {
        this.rootNode.addChildren(this.$store.getters.queryTree);
      }
      if (this.curCategory !== null) {
        this.selectNode("c"+this.curCategory.category_id);
      }
      if (this.curQuery !== null) {
        this.selectNode("q"+this.curQuery.query_id);
      }      
    },
    selectNode(key) {
      let node = $("#query_tree").fancytree("getTree").getNodeByKey(key);
      if (node !== null) {
        node.makeVisible({scrollIntoView: true});
        node.setActive();
      }
    },
    async addService() {
      this.$store.commit('setObjects',[]);
      let result = await carabiAdmin.addService(this.curModId,"Новый сервис");
      console.log("addService",result);
      await this.$store.dispatch('loadQueries');
      this.$router.push('/queries/category/'+result.ObjectID);
      //this.$store.dispatch('loadQueries');
    }
  },
  watch: {
    queryTree() {
      this.refreshTree();    
    },
    search() {
      this.refreshTree();
      this.$refs.search.focus();
    },
    curCategory() {
      if (this.curCategory === null) return;
      let curNode = $("#query_tree").fancytree("getActiveNode");
      if ((curNode !== null) && (curNode.data.ObjectID == this.curCategory.ObjectID)) return;
      this.selectNode("c"+this.curCategory.ObjectID);
    },
    curQuery() {
      if (this.curQuery === null) return;
      let curNode = $("#query_tree").fancytree("getActiveNode");
      if ((curNode !== null) && (curNode.data.ObjectID == this.curQuery.ObjectID)) return;
      this.selectNode("q"+this.curQuery.ObjectID);
    }   
  },  
  beforeMount() {},  
  mounted () {
    $("#query_tree").fancytree({
        source: [],
        extraClasses : "ws-wrap",
        activate: (event, data) => {
            let curData = data.node.data;
            console.log('data',data,curData);
            if (curData.TypeName == "QUERY") {
              this.$router.push('/queries/query/'+curData.ObjectID);
            } else {
              this.$router.push('/queries/category/'+curData.ObjectID);
            }
        }
    });
    this.rootNode = $("#query_tree").fancytree("getRootNode");
    console.log("rootNode",this.rootNode);
    this.refreshTree(); 
  }
}
</script>
 
<style>
#query_panel {
  display: flex;
  flex-direction: column;
  width: 400px;
}

#query_tree {
  padding: 0 10px;
  /*overflow-y: scroll;*/
  flex-grow: 1;
  display: flex;
}

#query_panel > .panel_add {
  min-height: 40px;
  padding: 10px 10px;
  display: flex;
}

.fancytree-container {
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  position: relative;
}

.fancytree-title > p {
  margin: 0;
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    { staticClass: "uk-table uk-table-small uk-table-divider" },
    [
      _vm._l(_vm.params, function (param, i) {
        return _c("tr", [
          _c("td", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: param.name,
                  expression: "param.name",
                },
              ],
              staticClass: "uk-input uk-form-small uk-width-1-1",
              attrs: { type: "text" },
              domProps: { value: param.name },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(param, "name", $event.target.value)
                },
              },
            }),
          ]),
          _c("td", [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: param.isInOrOut,
                    expression: "param.isInOrOut",
                  },
                ],
                staticClass: "uk-select uk-form-small",
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      param,
                      "isInOrOut",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              _vm._l(_vm.paramInOut, function (option) {
                return _c(
                  "option",
                  { domProps: { selected: param.isInOrOut == option } },
                  [_vm._v(_vm._s(option))]
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("td", [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: param.type,
                    expression: "param.type",
                  },
                ],
                staticClass: "uk-select uk-form-small",
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      param,
                      "type",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  },
                },
              },
              [
                _c("option"),
                _vm._v(" "),
                _vm._l(_vm.paramTypes, function (option) {
                  return _c(
                    "option",
                    { domProps: { selected: param.type == option } },
                    [_vm._v(_vm._s(option))]
                  )
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("td", [
            _c("a", {
              attrs: { href: "#", "uk-icon": "icon: close" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.delParam(i)
                },
              },
            }),
          ]),
        ])
      }),
      _vm._v(" "),
      _c("tr", [
        _c("td", { staticClass: "uk-text-center", attrs: { colspan: "4" } }, [
          _c(
            "button",
            {
              staticClass: "uk-button uk-button-small uk-button-primary",
              on: {
                click: function ($event) {
                  return _vm.addParam()
                },
              },
            },
            [_vm._v("+ параметр")]
          ),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
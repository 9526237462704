var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "modal", attrs: { "uk-modal": "" } }, [
    _c("div", { staticClass: "uk-modal-dialog" }, [
      _c("button", {
        staticClass: "uk-modal-close-default",
        attrs: { type: "button", "uk-close": "" },
      }),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "uk-modal-body" }, [
        _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              },
            },
          },
          [
            _c("div", [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.project,
                      expression: "project",
                    },
                  ],
                  staticClass: "uk-select",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.project = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                  },
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v("Выберите проект"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.projects, function (prj) {
                    return _c("option", { domProps: { value: prj.sysname } }, [
                      _vm._v(
                        _vm._s(prj.sysname) + " (" + _vm._s(prj.name) + ")"
                      ),
                    ])
                  }),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "uk-margin-small-top" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.login,
                    expression: "login",
                  },
                ],
                staticClass: "uk-input",
                attrs: {
                  type: "text",
                  placeholder: "Логин (если пусто - гостевой вход)",
                },
                domProps: { value: _vm.login },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.login = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "uk-margin-small-top" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password",
                  },
                ],
                staticClass: "uk-input",
                attrs: { type: "password", placeholder: "Пароль" },
                domProps: { value: _vm.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.password = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "uk-margin-small-top uk-text-center" }, [
              _vm.error ? _c("div", [_vm._v(_vm._s(_vm.error))]) : _vm._e(),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-modal-footer uk-text-right" }, [
        _c(
          "button",
          {
            staticClass: "uk-button uk-button-default uk-modal-close",
            attrs: { type: "button" },
          },
          [_vm._v("Отмена")]
        ),
        _vm._v(" "),
        !_vm.loading
          ? _c(
              "button",
              {
                staticClass: "uk-button uk-button-primary",
                attrs: { type: "button" },
                on: { click: _vm.submit },
              },
              [_vm._v("Вход")]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.loading
          ? _c(
              "button",
              {
                staticClass: "uk-button uk-button-primary",
                attrs: { type: "button", disabled: "" },
              },
              [_vm._v("Загрузка")]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "uk-modal-header" }, [
      _c("h2", { staticClass: "uk-modal-title" }, [_vm._v("Авторизация")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="branches">
    <div v-if="loading" class="branches-loader uk-text-center uk-padding">
      <div uk-spinner="ratio: 2"></div>
      <p>Загрузка...</p>
    </div>  
    <table v-else class="uk-table uk-table-striped uk-table-hover">
      <tr>
        <th>Проект</th>
        <th>Логин</th>
        <th>ID</th>
        <th>Токен</th>
        <th>IP</th>
        <th>Дата созд.</th>
        <th>Посл. обращение</th>
        <th>User Agent</th>
      </tr>
      <tr v-for="user in users">
        <td>{{user.project}}</td>
        <td>{{user.login}}</td>
        <td>{{user.oracle_user_id}}</td>
        <td>{{user.Token}}</td>
        <td>{{user.ip_addr_white}}</td>        
        <td>{{user.Created | moment('DD.MM.YY HH:mm')}}</td>
        <td>{{user.LastActive | moment('DD.MM.YY HH:mm')}}</td>
        <td>{{user.user_agent}}</td>        
      </tr>      
    </table>
  </div>	
</template>
 
<script>
import { mapState } from 'vuex';
import carabiAdmin from "../carabiAdmin.js";
import moment from 'moment'

export default {
  name: 'users',
  data () { return {
    users: null
  }; },
  computed: mapState({
    loading() {
      return this.users === null
    }
  }),
  async mounted () {
    await this.load()
    this.refreshInterval = setInterval(()=>{this.load()},5000)
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval)
  },
  methods: {
    async load() {
      let rawUsers = await carabiAdmin.getActiveUsers();
      let users = []
      rawUsers.forEach((user)=>{
        user.sessions.forEach((sess)=>{
          sess.project = user.project
          users.push(sess)
        })
      })
      this.users = users
    }
  },
}
</script>
 
<style>
/*
.projects {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
}
.projects-panel {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  overflow-y: scroll;
}
.projects-content {
    flex-grow: 1;
    overflow-y: scroll;
    padding: 0 10px;
}
*/
</style>
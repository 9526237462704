var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "branches" }, [
    _vm.loading
      ? _c(
          "div",
          { staticClass: "branches-loader uk-text-center uk-padding" },
          [
            _c("div", { attrs: { "uk-spinner": "ratio: 2" } }),
            _vm._v(" "),
            _c("p", [_vm._v("Загрузка...")]),
          ]
        )
      : _c(
          "table",
          { staticClass: "uk-table uk-table-striped uk-table-hover" },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm._l(_vm.users, function (user) {
              return _c("tr", [
                _c("td", [_vm._v(_vm._s(user.project))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(user.login))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(user.oracle_user_id))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(user.Token))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(user.ip_addr_white))]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm._f("moment")(user.Created, "DD.MM.YY HH:mm"))
                  ),
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(_vm._f("moment")(user.LastActive, "DD.MM.YY HH:mm"))
                  ),
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(user.user_agent))]),
              ])
            }),
          ],
          2
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("th", [_vm._v("Проект")]),
      _vm._v(" "),
      _c("th", [_vm._v("Логин")]),
      _vm._v(" "),
      _c("th", [_vm._v("ID")]),
      _vm._v(" "),
      _c("th", [_vm._v("Токен")]),
      _vm._v(" "),
      _c("th", [_vm._v("IP")]),
      _vm._v(" "),
      _c("th", [_vm._v("Дата созд.")]),
      _vm._v(" "),
      _c("th", [_vm._v("Посл. обращение")]),
      _vm._v(" "),
      _c("th", [_vm._v("User Agent")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "uk-modal-container uk-flex-top",
      attrs: { "uk-modal": "" },
    },
    [
      _c("div", { staticClass: "uk-modal-dialog" }, [
        _c("button", {
          staticClass: "uk-modal-close-default",
          attrs: { type: "button", "uk-close": "" },
        }),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "uk-modal-body" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.content,
                expression: "content",
              },
            ],
            staticClass: "uk-textarea",
            staticStyle: { height: "60vh" },
            attrs: { disabled: _vm.loading },
            domProps: { value: _vm.content },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.content = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-modal-footer uk-text-right" }, [
          _vm.error
            ? _c(
                "div",
                {
                  staticClass:
                    "uk-margin-small-bottom uk-text-center uk-text-danger",
                },
                [
                  _vm._v(
                    "\n              " + _vm._s(_vm.error) + "\n            "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "uk-button uk-button-default uk-modal-close",
              attrs: { type: "button" },
            },
            [_vm._v("Отмена")]
          ),
          _vm._v(" "),
          !_vm.loading
            ? _c(
                "button",
                {
                  staticClass: "uk-button uk-button-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.submit },
                },
                [_vm._v("Импорт")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.loading
            ? _c(
                "button",
                {
                  staticClass: "uk-button uk-button-primary",
                  attrs: { type: "button", disabled: "" },
                },
                [_vm._v("Загрузка")]
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "uk-modal-header" }, [
      _c("h2", { staticClass: "uk-modal-title" }, [_vm._v("Импрот категории")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }